import React from 'react'
import NavbarAuth from '../components/common/NavbarAuth'
import Form1 from '../components/ForgetPassword/Form1'

function ForgetPassword1() {
    return (
        <div>
            <NavbarAuth />
            <Form1 />
        </div>
    )
}

export default ForgetPassword1
