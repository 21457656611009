import React, { useState } from "react";
import { CameraIcon } from "../../../utils/Icons";
import { singleFileUpload } from "../../../api/FileUpload";
import toast from "react-hot-toast";
import { setUser } from "../../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, updateUserProfile } from "../../../api/authApi";
import ButtonLoader from "../../common/ButtonLoader";
import ImageCrop from "./ImageCrop"; // Import ImageCrop component

function ProfileImage({ data, setData }) {
  const [loading, setLoading] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const token = useSelector((state) => state.userData.token);
  const dispatch = useDispatch();

  const handleProfileFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsCropping(true);
    }
  };

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = new Image();
    image.src = URL.createObjectURL(imageSrc);
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    if (!ctx) throw new Error("Failed to create 2D context");

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  };

  const handleCropConfirm = async (cropPixels) => {
    if (!selectedFile || !cropPixels) return;
    
    setIsCropping(false);
    setLoading(true);

    try {
      const croppedImage = await getCroppedImg(selectedFile, cropPixels);
      if (!croppedImage) {
        toast.error("Failed to create cropped image.");
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("file", croppedImage, selectedFile.name);

      const result = await singleFileUpload(formData);
      if (result && result.data) {
        const updatedData = { ...data, profilePhoto: result.data };
        await updateUserProfile(updatedData, token);

        const userData = await getUserData(token);
        dispatch(setUser(userData.data));
        setData(updatedData);
        toast.success("Profile photo updated successfully!");
      } else {
        toast.error("Failed to update profile photo.");
      }
    } catch (error) {
      console.error("Error in handleCropConfirm:", error);
      toast.error("An error occurred while updating the profile photo.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center relative">
      <div className="w-32 h-32 sm:w-40 sm:h-40 relative">
        <img
          src={data?.profilePhoto || "../assets/images/homepage/image.png"}
          alt="Profile"
          className="w-full h-full rounded-full border-4 border-primary object-cover"
        />
        <button
          className="flex items-center justify-center absolute bottom-2 right-2 bg-primary rounded-full p-2 shadow-md hover:bg-black focus:outline-none transition"
          onClick={() => document.getElementById("profileFileInput").click()}
        >
          {loading ? <ButtonLoader /> : <CameraIcon size={15} color="white" />}
        </button>
      </div>
      <input
        type="file"
        id="profileFileInput"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleProfileFileChange}
      />
      {isCropping && (
        <ImageCrop
          selectedFile={selectedFile}
          aspect={1} 
          onConfirm={handleCropConfirm}
          onClose={() => setIsCropping(false)}
        />
      )}
    </div>
  );
}

export default ProfileImage;
