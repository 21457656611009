import React from "react";

function CategoryTable() {
  const data = [
    { download: "123", revenue: "$500", view: "1,200" },
    { download: "331", revenue: "$300", view: "900" },
    { download: "221", revenue: "$450", view: "1,500" },
    { download: "533", revenue: "$800", view: "2,000" },
    { download: "533", revenue: "$800", view: "2,000" },
    { download: "533", revenue: "$800", view: "2,000" },
  ];

  return (
    <div className="overflow-x-auto md:mt-16">
      <table className="min-w-full divide-y divide-gray-200">
        <thead >
          <tr >
            <th></th>
            <th scope="col" className="px-6 py-6 text-left text-md font-bold uppercase  text-primary tracking-wider">
              Download
            </th>
            <th scope="col" className="px-6 py-6 text-left text-md font-bold uppercase  text-primary tracking-wider">
              Revenue
            </th>
            <th scope="col" className="px-6 py-6 text-left text-,d font-bold uppercase  text-primary tracking-wider">
              View
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((item, index) => (
            <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
              <td className="px-6 py-4 whitespace-nowrap text-md text-secondary">Category 1</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">{item.download}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm ">{item.revenue}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm ">{item.view}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CategoryTable;
