import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import toast from "react-hot-toast";
import ButtonLoader from "../common/ButtonLoader";
import { forgetPassword } from "../../api/authApi";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
function Form2() {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const size = useWindowSize();
  const [timer, setTimer] = useState(30);
  const [showResendLink, setShowResendLink] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("forgetPasswordData");
    if (!storedData) {
      navigate("/forgot-password");
    } else {
      const parsedData = JSON.parse(storedData);
      setCredentials((prevCredentials) => ({
        ...prevCredentials,
        email: parsedData.email,
      }));
    }
  }, [navigate]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setShowResendLink(true);
    }
  }, [timer]);

  const validateOTP = (e) => {
    e.preventDefault();
  
    const storedData = localStorage.getItem("forgetPasswordData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const storedOTP = parsedData.otp;
  
      if (OTP === storedOTP) {
        toast.success("OTP Verified successfully!");
  
        // Update only the status field and keep the existing token, email, otp
        const updatedData = {
          ...parsedData,  // Keep the existing email, otp, token
          status: true,   // Update the status to true
        };
  
        // Store the updated data back into localStorage
        localStorage.setItem("forgetPasswordData", JSON.stringify(updatedData));
  
        // Navigate to the next page after successful verification
        navigate("/change-password");
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } else {
      toast.error("No OTP data found. Please request a new OTP.");
      navigate("/forgot-password");
    }
  };
  const handleResendOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await forgetPassword({ ...credentials });
      if (result && result.status === "success") {
        toast.success(result.message || "OTP Successfully Sent To Your Email!");
        const storedData = {
          email: credentials.email,
          token: result.data,
          otp: result.otp,
          status: false,
        };
        localStorage.setItem("forgetPasswordData", JSON.stringify(storedData));
        setTimer(30);
        setShowResendLink(false); 
      } else {
        toast.error(result.message || "Failed to Reset Password.");
      }
    } catch (error) {
      toast.error("An error occurred during Reset Password.");
    } finally {
      setLoading(false);
    }
  };

  const gap = size.width <= 768 ? "0px" : "10px";
  const height = size.width <= 768 ? "50px" : "70px";
  return (
    <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="flex flex-col md:flex-row items-center gap-8">
        <div className="w-full md:w-1/2 order-2">
          <div className="my-auto">
            <h2 className="text-2xl md:text-5xl text-gray-800 font-semibold mb-4">
              Verify OTP
            </h2>
            <p className="font-medium text-lg text-black text-opacity-70 mb-0">
              Enter your OTP to Reset Your Password.
            </p>
            <form onSubmit={validateOTP}>
              <div className="mb-3 mt-4">
                <div
                  className="otp-input-container-2 mt-8"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <OtpInput
                    value={OTP}
                    onChange={(otp) => setOTP(otp)}
                    numInputs={6}
                    renderSeparator={(index) => (
                      <span key={index} className="otp-separator">
                        {index < 5 ? " " : null}
                      </span>
                    )}
                    renderInput={(props) => (
                      <input {...props} className="otp-input-2" />
                    )}
                    inputStyle={{
                      width: "100%",
                      margin: "0 0.5rem",
                      fontSize: "1.5rem",
                      flexGrow: 1,
                      textAlign: "center",
                      borderRadius: "8px",
                      border: "2px solid rgba(0,0,0,0.3)",
                      height: height,
                    }}
                    containerStyle={{
                      width: "100%",
                      display: "flex",
                      gap: gap,
                    }}
                    isInputNum={true}
                  />
                </div>
              </div>

              <div className="text-right me-2 text-md hover:text-primary">
                {!showResendLink ? (
                  <span>Resend OTP in {timer} seconds</span>
                ) : (
                  <button type="submit" onClick={handleResendOTP}>
                    Resend OTP
                  </button>
                )}
              </div>

              <button
                type="submit"
                className="bg-secondary hover:bg-black rounded-3xl text-white w-full p-3 mt-8"
                disabled={OTP.length !== 6}
              >
                {loading ? <ButtonLoader /> : "Verify Code"}
              </button>
            </form>
          </div>
        </div>
        <div className="w-full md:w-1/2 order-1">
          <div className="w-full flex items-center justify-center">
            <img
              src="/assets/images/forcreators/getstarted.png"
              alt="Get Started"
              className="rounded-3xl object-cover md:w-[90%] w-[100%]"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Form2;
