import React from "react";
import BreadCrumb from "../components/common/BreadCrumb";
import ContactMain from "../components/ContactUsPage/ContactMain";
import Navbar from "../components/common/preRegistration/Navbar";
import Footer from "../components/common/preRegistration/Footer";
import MetaTags from "../components/common/MetaTags";

function ContactUs() {
  return (
    <>
    <MetaTags
  title="Contact Us | Aindrea NFT Marketplace"
  description="Have questions or feedback? Reach out to the Aindrea team via our Contact Us page. We're here to assist you!"
  url="/contact-us"
/>

      <Navbar />
      <BreadCrumb title="Contact Page" img={"/assets/images/contactus/contact-us.png"} />
      <ContactMain />
      <Footer />
    </>
  );
}

export default ContactUs;
