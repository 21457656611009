import React from "react";
import Navbar from "../../components/ContributorsPage/Navbar";
import Footer from "../../components/ContributorsPage/Footer";
import Main from "../../components/ContributorsPage/Profile/Main";
function Profile() {
  return (
    <>
      <Navbar />
      <Main/>
      <Footer />
    </>
  );
}

export default Profile;
