import { Search } from "lucide-react";
import React from "react";

// Sample transaction data
const transactions = [
  {
    date: "Apr 10, 2023",
    title: "Asset Title 1",
    type: "Sale",
    amount: 200,
    status: "Completed",
  },
  {
    date: "Apr 11, 2023",
    title: "Asset Title 2",
    type: "Rental",
    amount: 150,
    status: "Completed",
  },
  {
    date: "Apr 12, 2023",
    title: "Asset Title 3",
    type: "Video",
    amount: 300,
    status: "Pending",
  },
  {
    date: "Apr 13, 2023",
    title: "Asset Title 4",
    type: "Design",
    amount: 400,
    status: "Completed",
  },
  {
    date: "Apr 14, 2023",
    title: "Asset Title 5",
    type: "Sold",
    amount: 500,
    status: "Pending",
  },
  // Add more transactions as needed
];

function TransactionTable() {
  return (
    <section className="max-w-8xl mx-auto pb-12 px-4 sm:px-6 lg:px-24">
      <div className="flex flex-row justify-between items-center mb-8">
        <h2 className="md:text-3xl text-2xl font-semibold">
          Transaction History
        </h2>
      </div>
      <div className="flex flex-col-reverse sm:flex-row justify-between items-end mb-8">
        <div className="flex space-x-4 items-center mb-5 sm:mb-0">
          <div className="flex">
            <button className="border font-semibold rounded-3xl border-primary bg-transparent text-gray-700 p-2 text-md focus:outline-none appearance-none">
              Export as PDF
            </button>
          </div>
          <div className="flex">
            <button className="border font-semibold rounded-3xl border-primary bg-transparent text-gray-700 p-2 text-md focus:outline-none appearance-none">
              Export as CSV
            </button>
          </div>
        </div>
        <div className="relative w-full sm:max-w-sm mb-5 sm:mb-0">
          <input
            type="text"
            placeholder="Search here..."
            className="w-full py-2 pl-10 pr-4 text-md border border-primary rounded-3xl focus:outline-none focus:ring-1 focus:ring-primary"
          />
          <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
            <Search className="w-5 h-5 text-primary" />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-collapse">
          <thead>
            <tr className="text-black text-md leading-normal">
              <th className="py-3 px-6 text-left border-b">Date</th>
              <th className="py-3 px-6 text-left border-b">Asset Title</th>
              <th className="py-3 px-6 text-left border-b">Transaction Type</th>
              <th className="py-3 px-6 text-left border-b">Amount ($)</th>
              <th className="py-3 px-6 text-left border-b">Status</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-md">
            {transactions.map((transaction, index) => (
              <tr key={index} className="border-b">
                <td className="py-3 px-6">
                  <div className="flex items-center gap-3">
                    <h2>{transaction.date}</h2>
                  </div>
                </td>
                <td className="py-3 px-6">{transaction.title}</td>
                <td className="py-3 px-6">{transaction.type}</td>
                <td className="py-3 px-6">${transaction.amount}</td>
                <td
                  className={`py-3 px-6 ${
                    transaction.status === "Completed"
                      ? "text-green-700"
                      : "text-red-700"
                  }`}
                >
                  {transaction.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default TransactionTable;
