import React, { useState } from "react";
import ButtonLoader from "../../common/ButtonLoader";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { updateUserPassword } from "../../../api/authApi";

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    oldpassword: "",
    newpassword: "",
    confirmPassword: "",
  });
  const token = useSelector((state) => state.userData.token);
  const handleChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: inputType === "checkbox" ? checked : value,
    }));
  };

  const handlePassword = async (e) => {
    e.preventDefault();
    if (credentials.newpassword !== credentials.confirmPassword) {
      toast.error("New Password and Confirm Password do not match.");
      return;
    }
    setLoading(true);
    try {
      const result = await updateUserPassword(
        {
          currentPassword: credentials.oldpassword,
          newPassword: credentials.newpassword,
        },
        token
      );
      if (result && result.status === "success") {
        toast.success(result.message || "Password Updated");
      } else {
        toast.error(result.message || "Failed to Update.");
      }
    } catch (error) {
      toast.error("An error occurred during Updation.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handlePassword}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-4">
        <div className="w-full ">
          <div>
            <label
              htmlFor="oldpassword "
              className="font-semibold text-black text-opacity-70 "
            >
              Old Password
            </label>
            <input
              id="oldpassword"
              type="password"
              placeholder="Old Password"
              className=" border-[1px] border-primary shadow-sm  p-3 rounded-xl w-full  outline-black mt-2"
              required
              name="oldpassword"
              value={credentials?.oldpassword}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="w-full ">
          <div>
            <label
              htmlFor="newpassword"
              className="font-semibold text-black text-opacity-70 "
            >
              New Password
            </label>
            <input
              id="newpassword"
              type="password"
              placeholder="New Password"
              className=" border-[1px] border-primary shadow-sm  p-3 rounded-xl w-full  outline-black mt-2"
              required
              name="newpassword"
              value={credentials?.newpassword}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="w-full ">
          <div>
            <label
              htmlFor="newpassword"
              className="font-semibold text-black text-opacity-70 "
            >
              Confirm Password
            </label>
            <input
              id="confirmpassword"
              type="password"
              placeholder="Confirm Password"
              className=" border-[1px] border-primary shadow-sm  p-3 rounded-xl w-full  outline-black mt-2"
              required
              name="confirmPassword"
              value={credentials?.confirmPassword}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 md:w-1/4">
        <button
          type="submit"
          className="w-full font-semibold  bg-primary text-white py-3 rounded-3xl hover:bg-black"
        >
          {loading ? <ButtonLoader /> : "Update"}
        </button>
      </div>
    </form>
  );
}

export default ChangePassword;
