import React from "react";
import { Trash2 } from "lucide-react";
import { EditIcon, EyeIcon } from "../../../utils/Icons";
function Table() {
  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-collapse">
          <thead>
            <tr className="text-black  text-sm leading-normal">
              <th className="py-5 px-6 text-left border-b"></th>
              <th className="py-5 px-6 text-left border-b">Asset</th>
              <th className="py-5 px-6 text-left border-b">Title</th>
              <th className="py-5 px-6 text-left border-b">Category</th>
              <th className="py-5 px-6 text-left border-b">Upload Date</th>
              <th className="py-5 px-6 text-left border-b">Performance</th>
              <th className="py-5 px-6 text-left border-b">NFT Status</th>
              <th className="py-5 px-6 text-left border-b">Action</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm">
            <tr className="border-b">
              <td className="py-5 px-6">
                <input type="checkbox" name="" id="" />
              </td>
              <td className="py-5">
                <img
                  width={100}
                  src="/assets/images/CreatorsPages/MyLibrary/table.png"
                  alt=""
                />
              </td>
              <td className="py-5 px-6">Landscape</td>
              <td className="py-5 px-6">Photography</td>
              <td className="py-5 px-6">2024-6-7</td>
              <td className="py-5 px-6">$150</td>
              <td className="py-5 px-6 text-green-700">Completed</td>
              <td>
                <div className="flex">
                  <div className="bg-[#fbf5fb] p-1 rounded">
                    <EditIcon size={14} color="#CA63CE" />
                  </div>
                  <div className="bg-green-100 p-1 rounded mx-1">
                    <EyeIcon size={14} color="#6EB387" />
                  </div>
                  <div className="bg-red-100 p-1 rounded">
                    <Trash2 size={14} color="#f70e16" />
                  </div>
                </div>
              </td>
            </tr>
            <tr className="border-b">
              <td className="py-5 px-6">
                <input type="checkbox" name="" id="" />
              </td>
              <td className="py-5">
                <img
                  width={100}
                  src="/assets/images/CreatorsPages/MyLibrary/table.png"
                  alt=""
                />
              </td>
              <td className="py-5 px-6">Landscape</td>
              <td className="py-5 px-6">Photography</td>
              <td className="py-5 px-6">2024-6-7</td>
              <td className="py-5 px-6">$150</td>
              <td className="py-5 px-6 text-green-700">Completed</td>
              <td>
                <div className="flex">
                  <div className="bg-[#fbf5fb] p-1 rounded">
                    <EditIcon size={14} color="#CA63CE" />
                  </div>
                  <div className="bg-green-100 p-1 rounded mx-1">
                    <EyeIcon size={14} color="#6EB387" />
                  </div>
                  <div className="bg-red-100 p-1 rounded">
                    <Trash2 size={14} color="#f70e16" />
                  </div>
                </div>
              </td>
            </tr>
            <tr className="border-b">
              <td className="py-5 px-6">
                <input type="checkbox" name="" id="" />
              </td>
              <td className="py-5">
                <img
                  width={100}
                  src="/assets/images/CreatorsPages/MyLibrary/table.png"
                  alt=""
                />
              </td>
              <td className="py-5 px-6">Landscape</td>
              <td className="py-5 px-6">Photography</td>
              <td className="py-5 px-6">2024-6-7</td>
              <td className="py-5 px-6">$150</td>
              <td className="py-5 px-6 text-green-700">Completed</td>
              <td>
                <div className="flex">
                  <div className="bg-[#fbf5fb] p-1 rounded">
                    <EditIcon size={14} color="#CA63CE" />
                  </div>
                  <div className="bg-green-100 p-1 rounded mx-1">
                    <EyeIcon size={14} color="#6EB387" />
                  </div>
                  <div className="bg-red-100 p-1 rounded">
                    <Trash2 size={14} color="#f70e16" />
                  </div>
                </div>
              </td>
            </tr>
            <tr className="border-b">
              <td className="py-5 px-6">
                <input type="checkbox" name="" id="" />
              </td>
              <td className="py-5">
                <img
                  width={100}
                  src="/assets/images/CreatorsPages/MyLibrary/table.png"
                  alt=""
                />
              </td>
              <td className="py-5 px-6">Landscape</td>
              <td className="py-5 px-6">Photography</td>
              <td className="py-5 px-6">2024-6-7</td>
              <td className="py-5 px-6">$150</td>
              <td className="py-5 px-6 text-green-700">Completed</td>
              <td>
                <div className="flex">
                  <div className="bg-[#fbf5fb] p-1 rounded">
                    <EditIcon size={14} color="#CA63CE" />
                  </div>
                  <div className="bg-green-100 p-1 rounded mx-1">
                    <EyeIcon size={14} color="#6EB387" />
                  </div>
                  <div className="bg-red-100 p-1 rounded">
                    <Trash2 size={14} color="#f70e16" />
                  </div>
                </div>
              </td>
            </tr>
            <tr className="border-b">
              <td className="py-5 px-6">
                <input type="checkbox" name="" id="" />
              </td>
              <td className="py-5">
                <img
                  width={100}
                  src="/assets/images/CreatorsPages/MyLibrary/table.png"
                  alt=""
                />
              </td>
              <td className="py-5 px-6">Landscape</td>
              <td className="py-5 px-6">Photography</td>
              <td className="py-5 px-6">2024-6-7</td>
              <td className="py-5 px-6">$150</td>
              <td className="py-5 px-6 text-green-700">Completed</td>
              <td>
                <div className="flex">
                  <div className="bg-[#fbf5fb] p-1 rounded">
                    <EditIcon size={14} color="#CA63CE" />
                  </div>
                  <div className="bg-green-100 p-1 rounded mx-1">
                    <EyeIcon size={14} color="#6EB387" />
                  </div>
                  <div className="bg-red-100 p-1 rounded">
                    <Trash2 size={14} color="#f70e16" />
                  </div>
                </div>
              </td>
            </tr>

            <tr className="border-b">
              <td className="py-5 px-6">
                <input type="checkbox" name="" id="" />
              </td>
              <td className="py-5">
                <img
                  width={100}
                  src="/assets/images/CreatorsPages/MyLibrary/table.png"
                  alt=""
                />
              </td>
              <td className="py-5 px-6">Landscape</td>
              <td className="py-5 px-6">Photography</td>
              <td className="py-5 px-6">2024-6-7</td>
              <td className="py-5 px-6">$150</td>
              <td className="py-5 px-6 text-green-700">Completed</td>
              <td>
                <div className="flex">
                  <div className="bg-[#fbf5fb] p-1 rounded">
                    <EditIcon size={14} color="#CA63CE" />
                  </div>
                  <div className="bg-green-100 p-1 rounded mx-1">
                    <EyeIcon size={14} color="#6EB387" />
                  </div>
                  <div className="bg-red-100 p-1 rounded">
                    <Trash2 size={14} color="#f70e16" />
                  </div>
                </div>
              </td>
            </tr>
            <tr className="border-b">
              <td className="py-5 px-6">
                <input type="checkbox" name="" id="" />
              </td>
              <td className="py-5">
                <img
                  width={100}
                  src="/assets/images/CreatorsPages/MyLibrary/table.png"
                  alt=""
                />
              </td>
              <td className="py-5 px-6">Landscape</td>
              <td className="py-5 px-6">Photography</td>
              <td className="py-5 px-6">2024-6-7</td>
              <td className="py-5 px-6">$150</td>
              <td className="py-5 px-6 text-green-700">Completed</td>
              <td>
                <div className="flex">
                  <div className="bg-[#fbf5fb] p-1 rounded">
                    <EditIcon size={14} color="#CA63CE" />
                  </div>
                  <div className="bg-green-100 p-1 rounded mx-1">
                    <EyeIcon size={14} color="#6EB387" />
                  </div>
                  <div className="bg-red-100 p-1 rounded">
                    <Trash2 size={14} color="#f70e16" />
                  </div>
                </div>
              </td>
            </tr>
            <tr className="">
              <td className="py-5 px-6">
                <input type="checkbox" name="" id="" />
              </td>
              <td className="py-5">
                <img
                  width={100}
                  src="/assets/images/CreatorsPages/MyLibrary/table.png"
                  alt=""
                />
              </td>
              <td className="py-5 px-6">Brand Logo</td>
              <td className="py-5 px-6">Design</td>
              <td className="py-5 px-6 ">2024-9-6</td>
              <td className="py-5 px-6">$200</td>
              <td className="py-5 px-6 text-red-700">Incompleted</td>
              <td>
                <div className="flex">
                  <div className="bg-[#fbf5fb] p-1 rounded">
                    <EditIcon size={14} color="#CA63CE" />
                  </div>
                  <div className="bg-green-100 p-1 rounded mx-1">
                    <EyeIcon size={14} color="#6EB387" />
                  </div>
                  <div className="bg-red-100 p-1 rounded">
                    <Trash2 size={14} color="#f70e16" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-center items-center mt-7">
        <button className="w-full md:w-1/6 bg-primary hover:bg-black transition duration-300 ease-in-out text-white py-5 rounded-full">
          View More
        </button>
      </div>
    </>
  );
}

export default Table;
