import React from "react";
import Navbar from "../../components/ContributorsPage/Navbar";
import Stats from "../../components/ContributorsPage/Dashboard/Stats";
import RecentActivities from "../../components/ContributorsPage/Dashboard/RecentActivities";
import Footer from "../../components/ContributorsPage/Footer";

function Dashboard() {
  return (
    <div>
      <Navbar />
      <Stats />
      <RecentActivities />
      <Footer />
    </div>
  );
}

export default Dashboard;
