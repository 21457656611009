import React from "react";
import { AlertTriangle, ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";

const ErrorPage = () => (
  <div
    id="page-container"
    className="min-h-screen flex items-center justify-center "
  >
    <main className="text-center p-6">
      <div className="py-4">
        <div className="text-9xl font-bold text-primary flex items-center justify-center">
          <AlertTriangle className="opacity-50 mr-2" size={96} /> 404
        </div>
        <h1 className="text-2xl font-bold mt-5 mb-2">
          Oops.. You just found an error page..
        </h1>
        <h2 className="text-lg font-medium text-gray-500 mb-5">
          We are sorry but the page you are looking for was not found..
        </h2>
        <Link
          to="/"
          className="px-6 py-2 bg-secondary inline-flex hover:bg-black transition duration-300 ease-in-out text-white rounded-full"
        >
          <ArrowLeft className="opacity-50 mr-2" size={24} /> Back To Home
        </Link>
      </div>
    </main>
  </div>
);

export default ErrorPage;
