import React from "react";
import { Facebook, Instagram, ArrowUp, Twitter } from "lucide-react";

function Footer() {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="pt-16 border-t">
      <div
        className="max-w-8xl mx-auto  px-4 sm:px-6 lg:px-24 pb-12"
        style={{
          backgroundImage: 'url("/assets/images/footer.png")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5">
          {/* Logo and Social Media */}
          <div className="mb-5">
            <img
              src="/assets/images/logo.svg"
              alt="Logo"
              width={80}
              height={80}
            />
            <h3 className="font-bold text-lg mb-2 mt-4">Follow Us on:</h3>
            <div className="flex space-x-4">
              <Facebook className="w-6 h-6 text-primary hover:text-black" />
              <Twitter className="w-6 h-6 text-primary hover:text-black" />
              <Instagram className="w-6 h-6 text-primary hover:text-black" />
              <div className="w-6 h-6 bg-primary hover:bg-black rounded-full flex items-center justify-center text-white font-bold">
                G
              </div>
            </div>
          </div>

          {/* Links */}
          <div>
            <h3 className="font-bold mb-4 text-lg">Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="/" className="text-[#777777] hover:text-primary">
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/#whyjoin"
                  className="text-[#777777] hover:text-primary"
                >
                  About Us{" "}
                </a>
              </li>
              <li>
                <a
                  href="/#howitworks"
                  className="text-[#777777] hover:text-primary"
                >
                  Marketplace{" "}
                </a>
              </li>
            </ul>
          </div>
          <div>
            <ul className="space-y-2 md:mt-11 mt-2">
              <li>
                <a
                  href="/#testimonials"
                  className="text-[#777777] hover:text-primary"
                >
                  How It Works{" "}
                </a>
              </li>
              <li>
                <a href="/#blogs" className="text-[#777777] hover:text-primary">
                  For Creators{" "}
                </a>
              </li>

              <li>
                <a href="/#blogs" className="text-[#777777] hover:text-primary">
                  Community{" "}
                </a>
              </li>
            </ul>
          </div>

          <div>
            <ul className="space-y-2 md:mt-11 mt-2">
              <li>
                <a
                  href="/invest-in-aindera"
                  className="text-[#777777] hover:text-primary"
                >
                  Invest in AiNDREA{" "}
                </a>
              </li>
              <li>
                <a href="/#blogs" className="text-[#777777] hover:text-primary">
                  Support{" "}
                </a>
              </li>

              <li>
                <a href="/#blogs" className="text-[#777777] hover:text-primary">
                  Contact{" "}
                </a>
              </li>
            </ul>
          </div>
          {/* Additional Resources and Newsletter */}
          <div>
            <h3 className="font-bold text-lg md:mb-2 mb-5  mt-5 md:mt-0">
              SUBSCRIBE TO OUR NEWSLETTER
            </h3>
            <div className="flex mb-4">
              <input
                type="email"
                placeholder="Enter Email"
                className="flex-grow px-4 py-2 rounded-l-full bg-purple-50 focus:outline-none"
              />
              <button className="bg-primary hover:bg-black transition duration-300 ease-in-out text-white px-4 py-2 rounded-r-full">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright */}
      <div className="py-2 flex w-full px-5 bg-[rgba(202,99,206,0.15)]">
        <div className="flex items-center ms-auto">
          <p className="text-sm text-gray-900">
            Copyrights © 2024 All Rights Reserved by AiNDREA.
          </p>
        </div>
        <button
          onClick={scrollTop}
          className="bg-primary hover:bg-black ms-auto text-white p-2 rounded-full transition duration-300 ease-in-out"
        >
          <ArrowUp className="w-5 h-5" />
        </button>
      </div>
    </footer>
  );
}

export default Footer;
