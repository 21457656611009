import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BinIcon, EditIcon, EyeIcon } from "../../../utils/Icons";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { deleteUserAsset } from "../../../api/Assets";
import ButtonLoader from "../../common/ButtonLoader";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import Image from "../Previews/Image";
import ThreedModel from "../Previews/3dModel";
import Video from "../Previews/Video";
import Music from "../Previews/Music";
import moment from "moment";
import Avatar from "../Previews/Avatar";

function GridView({ loading, assets, removeAsset }) {
  const navigate = useNavigate();
  const token = useSelector((state) => state.userData.token);
  const [delLoadingId, setDelLoadingId] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);

  if (loading) {
    // Render skeleton loading
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {Array(4)
          .fill()
          .map((_, idx) => (
            <div
              key={idx}
              className="bg-white shadow-sm shadow-primary rounded-3xl overflow-hidden flex flex-col justify-between"
            >
              <div className="relative p-4">
                <Skeleton height={216} className="rounded-3xl" />
              </div>

              <div>
                <div className="p-4">
                  <div className="mb-2 flex justify-between">
                    <Skeleton width={100} />
                    <Skeleton width={100} />
                  </div>
                  <div className="mb-2 flex justify-between">
                    <Skeleton width={80} />
                    <Skeleton width={80} />
                  </div>
                  <div className="mb-2 flex justify-between">
                    <Skeleton width={120} />
                    <Skeleton width={120} />
                  </div>
                  <div className="mb-2 flex justify-between">
                    <Skeleton width={60} />
                    <Skeleton width={60} />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }

  if (assets.length === 0) {
    return (
      <div className="flex justify-center items-center h-[50vh]">
        <p>No assets found.</p>
      </div>
    );
  }

  const handleDelete = async () => {
    setDelLoadingId(selectedAssetId);
    try {
      const result = await deleteUserAsset(selectedAssetId, token);
      if (result && result.status === "success") {
        toast.success(result.message || "Deleted Successfully!");
        removeAsset(selectedAssetId);
      } else {
        toast.error(result.message || "Failed to Delete.");
      }
    } catch (error) {
      toast.error("An error occurred during Delete.");
    } finally {
      setDelLoadingId(null);
      setShowDeleteModal(false);
    }
  };

  const openDeleteModal = (assetId) => {
    setSelectedAssetId(assetId);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {assets.map((asset) => (
          <div className="bg-white shadow-sm shadow-primary rounded-3xl overflow-hidden">
            <div className="relative p-4">
              <div className="relative ">
                <div className="flex items-center justify-center w-full h-[300px] rounded-3xl">
                  {(() => {
                    switch (asset?.category) {
                      case "Images":
                        return <Image data={asset?.file} alt={asset?.title} />;
                      case "3D Models":
                        return (
                          <ThreedModel data={asset?.file} alt={asset?.title} />
                        );
                      case "Videos":
                        return <Video data={asset?.file} />;
                      case "Music":
                        return <Music data={asset?.file} />;
                      case "AI Avatars":
                        return <Avatar data={asset?.file} />;
                      default:
                        return "Unable to Preview";
                    }
                  })()}
                </div>

                <div className="absolute top-4 right-4 flex flex-col space-y-2">
                  <button
                    onClick={() => navigate(`/contributor/asset/${asset._id}`)}
                    className="bg-white p-1 rounded"
                  >
                    <EditIcon size={18} />
                  </button>
                  <button
                    onClick={() => navigate(`/contributor/asset/${asset._id}`)}
                    className="bg-white p-1 rounded"
                  >
                    <EyeIcon color="black" size={18} />
                  </button>
                  <button
                    onClick={() => openDeleteModal(asset._id)}
                    className="bg-white p-1 rounded"
                    disabled={delLoadingId === asset._id}
                  >
                    {delLoadingId === asset._id ? (
                      <ButtonLoader />
                    ) : (
                      <BinIcon size={18} />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div className="p-4">
                <div className="mb-2 flex justify-between">
                  <div className="font-medium">Title</div>
                  <div className="ml-5 text-end">
                    <div className="text-[#636363] truncate max-w-xs">
                      {asset?.title?.split(" ").slice(0, 3).join(" ") +
                        (asset?.title?.split(" ").length > 4 ? "..." : "")}
                    </div>
                  </div>
                </div>
                <div className="mb-2 flex justify-between">
                  <div className="font-medium">Category</div>
                  <div className="text-[#636363]">
                    {asset?.category || "N/A"}
                  </div>
                </div>
                <div className="mb-2 flex justify-between">
                  <div className="font-medium">Date Uploaded</div>
                  <div className="text-[#636363]">
                    {moment(asset?.createdAt).format("DD MMMM, YYYY")}
                  </div>
                </div>
                <div className="mb-2 flex justify-between">
                  <div className="font-medium">Price</div>
                  <div className="text-[#636363]">
                    ${asset?.price.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showDeleteModal && (
        <DeleteConfirmationModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
          loading={delLoadingId}
        />
      )}
    </>
  );
}

export default GridView;
