import React from "react";
import Slider from "react-slick";
import { ArrowLeft, ArrowRight, ArrowDown } from "lucide-react";
import Card from "./Card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const categories = [
  {
    name: "Category 1",
    image: "../assets/images/homepage/cont4.png",
  },
  {
    name: "Category 2",
    image: "../assets/images/homepage/cont4.png",
  },
  {
    name: "Category 3",
    image: "../assets/images/homepage/cont4.png",
  },
  {
    name: "Category 4",
    image: "../assets/images/homepage/cont4.png",
  },
  {
    name: "Category 5",
    image: "../assets/images/homepage/cont4.png",
  },
  {
    name: "Category 6",
    image: "../assets/images/homepage/cont4.png",
  },
];

// Custom Arrow Components
const PreviousArrow = ({ onClick }) => (
  <div
    className="cursor-pointer"
    onClick={onClick}
    style={{
      position: "absolute",
      left: "0",
      top: "50%",
      transform: "translateY(-50%)",
    }}
  >
    <ArrowLeft className="text-secondary" size={30} />
  </div>
);

const NextArrow = ({ onClick }) => (
  <div
    className="cursor-pointer"
    onClick={onClick}
    style={{
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
    }}
  >
    <ArrowRight className="text-secondary" size={30} />
  </div>
);

function Products() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    prevArrow: <PreviousArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,

        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        },
      },
    ],
  };

  return (
    <div className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-10 text-center relative">
          <div className="relative">
            <div className="w-full relative">
              <Slider {...settings}>
                {categories.map((category, index) => (
                  <div key={index}>
                    <button className="border min-w-40 ml-8 border-secondary px-3 py-3 rounded-3xl flex  justify-around hover:bg-secondary">
                      <div>{category.name}</div>
                      <img
                        className="ml-3 rounded-full w-7 h-7"
                        src={category.image}
                        alt={category.name}
                      />
                    </button>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-2 flex items-center justify-end text-sm">
          Sort By Popular
          <ArrowDown size={20} className="text-secondary ms-2" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 mt-5 gap-7">
        <Card />
        <Card />
        <Card />
        <Card />
      </div>
    </div>
  );
}

export default Products;
