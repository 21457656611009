import React from "react";
import Navbar from "../components/common/preRegistration/Navbar";
import Hero from "../components/PreRegistrationPage/Hero";
import Partners from "../components/PreRegistrationPage/Partners";
import Testimonials from "../components/PreRegistrationPage/Testimonials";
import WhyJoin from "../components/PreRegistrationPage/WhyJoin";
import Process from "../components/PreRegistrationPage/Process";
import Footer from "../components/common/preRegistration/Footer";
import CustomCursor from "../components/common/preRegistration/CustomCursor";
import MetaTags from "../components/common/MetaTags";

function PreRegisteration() {
  return (
    <>
    <MetaTags
        title="Welcome To Aindrea | The Next Generation of NFT Marketplace"
        description="Buy, sell, and trade exclusive NFTs on Aindrea – a secure, innovative marketplace for digital collectibles"
        url="/"
      />
      <Navbar />
      <Hero />
      <Partners />
      <WhyJoin />
      <Process />
      <Testimonials />
      {/* <Offer /> */}
      {/* <About />
      <Blogs /> */}
      <Footer />
      <CustomCursor />
    </>
  );
}

export default PreRegisteration;
