import { legacy_createStore as createStore } from "redux";
import reducers from "./reducers/index";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import sessionStorage from "redux-persist/lib/storage/session";

const persistConfig = {
 key: "main_root",
 storage,
 // storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
 persistedReducer,
 window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const Persistor = persistStore(store);

export { Persistor };
export default store;
