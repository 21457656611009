import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Wallet } from "lucide-react";

ChartJS.register(ArcElement, Tooltip, Legend);

function EarningGraph() {
  const data = {
    labels: ["All Sales", "Fees", "Tax"],
    datasets: [
      {
        label: "Earnings Breakdown",
        data: [5000, 1500, 500],
        backgroundColor: ["#CA63CE", "#6EB387", "#d4d4d4"],
        borderWidth: 1,
      },
    ],
  };
  const totalEarnings = data.datasets[0].data.reduce(
    (acc, curr) => acc + curr,
    0
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    cutout: "88%",
  };

  return (
    <div className="relative w-[75%] mx-auto mb-12">
      <div className="font-bold text-lg mt-8 mb-6 text-center">Earnings</div>
      <Doughnut data={data} options={options} />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <div className=" flex flex-col justify-center items-center mt-24">
          <Wallet size={44} className="text-primary" />
          <span className="font-bold text-2xl">${totalEarnings}</span>
          <div className="text-2xl">Balance</div>
        </div>
      </div>
    </div>
  );
}

export default EarningGraph;
