import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { CalendarDays } from "lucide-react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function SaleGraph() {
  const [dateRange, setDateRange] = useState("Last 7 Days");

  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };

  const data = {
    labels: [
      "Jan 1",
      "Jan 2",
      "Jan 3",
      "Jan 4",
      "Jan 5",
      "Jan 6",
      "Jan 7",
      "Jan 8",
    ],
    datasets: [
      {
        label: "Earnings in $",
        data: [1200, 1900, 3000, 5000, 2300, 3200, 4500, 5200],
        borderColor: "#CA63CE",
        borderWidth: 3,
        fill: true,
        tension: 0.3,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `$${value}`,
        },
        grid: {
          display: true,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div>
      <div className="font-bold text-lg mt-8 mb-12">Sales Report</div>
      <div className="flex justify-around items-center space-x-4 mb-6">
        <div>Showing For</div>

        <div className="flex">
          <label htmlFor="dateDropdown" className="mr-2 font-semibold">
            <CalendarDays className="text-primary" />
          </label>
          <select
            id="dateDropdown"
            value={dateRange}
            onChange={handleDateRangeChange}
            className="border-0 outline-none focus:ring-0"
          >
            <option value="Last 7 Days">Last 7 Days</option>
            <option value="Last 30 Days">Last 30 Days</option>
            <option value="This Month">This Month</option>
            <option value="Last Month">Last Month</option>
          </select>
        </div>
      </div>
      <Line data={data} options={options} />
    </div>
  );
}

export default SaleGraph;
