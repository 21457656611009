import React from "react";
import { CopyIcon } from "../../../utils/Icons";
import toast from "react-hot-toast";

function SocialLinks({ userData, handleChange }) {
  // Function to copy the input value to the clipboard
  const copyToClipboard = (value) => {
    if (value) {
      navigator.clipboard.writeText(value);
      toast.success("Copied to clipboard!");
    } else {
      toast.error("Nothing to copy");
    }
  };

  return (
    <div>
      <div className="mt-4 border-[1px] border-primary rounded-full w-full">
        <div className="flex items-center justify-between py-3 w-full px-3">
          <input
            type="url"
            value={userData?.profileLink || ""}
            name="profileLink"
            onChange={handleChange}
            className="text-opacity-70 bg-transparent focus:outline-none cursor-default w-full"
            placeholder="https://website.com"
          />
          <button onClick={() => copyToClipboard(userData?.profileLink)} className="ml-2">
            <CopyIcon color="#CA63CE" />
          </button>
        </div>
      </div>
      <div>
        <h2 className="text-black text-opacity-70 font-bold md:text-lg text-left mt-5">
          Social Media
        </h2>
        <div className="mt-4">
          <label className="font-semibold text-black text-opacity-70">
            Instagram
          </label>
        </div>
        <div className="mt-4 border-[1px] border-primary rounded-full w-full">
          <div className="flex items-center justify-between py-3 w-full px-3">
            <input
              type="url"
              value={userData?.instagram || ""}
              name="instagram"
              onChange={handleChange}
              className="text-opacity-70 bg-transparent focus:outline-none cursor-default w-full"
              placeholder="https://instagram.com"
            />
            <button onClick={() => copyToClipboard(userData?.instagram)} className="ml-2">
              <CopyIcon color="#CA63CE" />
            </button>
          </div>
        </div>
        <div className="mt-4">
          <label className="font-semibold text-black text-opacity-70">
            Facebook
          </label>
        </div>
        <div className="mt-4 border-[1px] border-primary rounded-full w-full">
          <div className="flex items-center justify-between py-3 w-full px-3">
            <input
              type="url"
              value={userData?.facebook || ""}
              name="facebook"
              onChange={handleChange}
              className="text-opacity-70 bg-transparent focus:outline-none cursor-default w-full"
              placeholder="https://facebook.com"
            />
            <button onClick={() => copyToClipboard(userData?.facebook)} className="ml-2">
              <CopyIcon color="#CA63CE" />
            </button>
          </div>
        </div>
        <div className="mt-4">
          <label className="font-semibold text-black text-opacity-70">
            Pinterest
          </label>
        </div>
        <div className="mt-4 border-[1px] border-primary rounded-full w-full">
          <div className="flex items-center justify-between py-3 w-full px-3">
            <input
              type="url"
              value={userData?.pinterest || ""}
              name="pinterest"
              onChange={handleChange}
              className="text-opacity-70 bg-transparent focus:outline-none cursor-default w-full"
              placeholder="https://pinterest.com"
            />
            <button onClick={() => copyToClipboard(userData?.pinterest)} className="ml-2">
              <CopyIcon color="#CA63CE" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialLinks;
