import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Trash2 } from "lucide-react";
import { EditIcon, EyeIcon } from "../../../utils/Icons";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { deleteUserAsset } from "../../../api/Assets";
import ButtonLoader from "../../common/ButtonLoader";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import Image from "../Previews/Image";
import ThreedModel from "../Previews/3dModel";
import Video from "../Previews/Video";
import Music from "../Previews/Music";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import Avatar from "../Previews/Avatar";

function TableView({ loading, assets, removeAsset }) {
  const token = useSelector((state) => state.userData.token);
  const [delLoadingId, setDelLoadingId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);

  if (loading) {
    return (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-collapse">
          <thead>
            <tr className="text-black text-md leading-normal">
              <th className="py-3 px-6 text-left border-b"></th>
              <th className="py-3 px-6 text-left border-b">Asset</th>
              <th className="py-3 px-6 text-left border-b">Title</th>
              <th className="py-3 px-6 text-left border-b">Category</th>
              <th className="py-3 px-6 text-left border-b">Upload Date</th>
              <th className="py-3 px-6 text-left border-b">Performance</th>
              <th className="py-3 px-6 text-left border-b">NFT Status</th>
              <th className="py-3 px-6 text-left border-b">Action</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-md">
            {Array(4)
              .fill()
              .map((_, idx) => (
                <tr className="border-b" key={idx}>
                  <td className="py-3 px-6">
                    <Skeleton height={24} width={24} />
                  </td>
                  <td className="py-3">
                    <Skeleton height={60} width={100} />
                  </td>
                  <td className="py-3 px-6">
                    <Skeleton width={100} />
                  </td>
                  <td className="py-3 px-6">
                    <Skeleton width={80} />
                  </td>
                  <td className="py-3 px-6">
                    <Skeleton width={100} />
                  </td>
                  <td className="py-3 px-6">
                    <Skeleton width={60} />
                  </td>
                  <td className="py-3 px-6">
                    <Skeleton width={80} />
                  </td>
                  <td className="py-3 px-6">
                    <div className="flex gap-2">
                      <Skeleton height={30} width={30} />
                      <Skeleton height={30} width={30} />
                      <Skeleton height={30} width={30} />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  if (assets.length === 0) {
    return (
      <div className="flex justify-center items-center h-[50vh]">
        <p>No assets found.</p>
      </div>
    );
  }

  const handleDelete = async () => {
    setDelLoadingId(selectedAssetId);
    try {
      const result = await deleteUserAsset(selectedAssetId, token);
      if (result && result.status === "success") {
        toast.success(result.message || "Deleted Successfully!");
        removeAsset(selectedAssetId);
      } else {
        toast.error(result.message || "Failed to Delete.");
      }
    } catch (error) {
      toast.error("An error occurred during Delete.");
    } finally {
      setDelLoadingId(null);
      setShowDeleteModal(false);
    }
  };

  const openDeleteModal = (assetId) => {
    setSelectedAssetId(assetId);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-collapse">
          <thead>
            <tr className="text-black text-md leading-normal">
              <th className="py-3 px-6 text-left border-b"></th>
              <th className="py-3 px-6 text-left border-b">Asset</th>
              <th className="py-3 px-6 text-left border-b">Title</th>
              <th className="py-3 px-6 text-left border-b">Category</th>
              <th className="py-3 px-6 text-left border-b">Upload Date</th>
              <th className="py-3 px-6 text-left border-b">Performance</th>
              <th className="py-3 px-6 text-left border-b">NFT Status</th>
              <th className="py-3 px-6 text-left border-b">Action</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-md">
            {assets.map((asset) => (
              <tr className="border-b" key={asset?._id}>
                <td className="py-3 px-6">
                  <input type="checkbox" />
                </td>
                <td className="py-3">
                  <div className="w-[125px] h-[100px] rounded-3xl">
                    {(() => {
                      switch (asset?.category) {
                        case "Images":
                          return (
                            <Image data={asset?.file} alt={asset?.title} />
                          );
                        case "3D Models":
                          return (
                            <ThreedModel
                              data={asset?.file}
                              alt={asset?.title}
                            />
                          );
                        case "Videos":
                          return <Video data={asset?.file} />;
                        case "Music":
                          return <Music data={asset?.file} />;
                        case "AI Avatars":
                          return <Avatar data={asset?.file} />;
                        default:
                          return "Unable to Preview";
                      }
                    })()}
                  </div>
                </td>
                <td className="py-3 px-6">{asset?.title}</td>
                <td className="py-3 px-6">{asset?.category || "N/A"}</td>
                <td className="py-3 px-6">
                {moment(asset?.createdAt).format('DD MMMM, YYYY')}
                </td>
                <td className="py-3 px-6">${asset?.price.toFixed(2)}</td>
                <td
                  className={`py-3 px-6 ${
                    asset?.nftStatus ? "text-primary" : "text-red-700"
                  }`}
                >
                  {asset?.nftStatus ? "Completed" : "Incomplete"}
                </td>
                <td>
                  <div className="flex gap-2">
                    <Link to={`/contributor/asset/${asset._id}`} className="bg-[#fbf5fb] p-1 rounded">
                      <EditIcon size={18} color="#CA63CE" />
                    </Link>
                    <Link to={`/contributor/asset/${asset._id}`} className="bg-green-100 p-1 rounded mx-1">
                      <EyeIcon size={18} color="#6EB387" />
                    </Link>
                    <button
                      onClick={() => openDeleteModal(asset._id)}
                      className="bg-red-100 p-1 rounded"
                      disabled={delLoadingId === asset._id}
                    >
                      {delLoadingId === asset._id ? (
                        <ButtonLoader />
                      ) : (
                        <Trash2 size={18} />
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showDeleteModal && (
        <DeleteConfirmationModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
          loading={delLoadingId}
        />
      )}
    </>
  );
}

export default TableView;
