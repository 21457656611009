import React from "react";
import ContactMain from "../ContactUsPage/ContactMain";
import BreadCrumb from "../common/BreadCrumb";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function ContactUsCont() {
  return (
    <>
      <Navbar />
      <BreadCrumb
        title="Contact Page"
        img={"/assets/images/contactus/contact-us.png"}
      />
      <ContactMain />
      <Footer />
    </>
  );
}
