import { useState, useEffect } from "react";
import { ChevronDown, ChevronUp, Menu, X } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/actions/logoutUser";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const userData = useSelector((state) => state.userData.user);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  return (
    <>
      <nav className="bg-white border-b">
        <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-24">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/contributor/dashboard">
                  <img src="/assets/images/logo.svg" alt="Logo" width={"70"} />
                </Link>
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              <NavLink href="/contributor/dashboard" location={location}>
                Dashboard
              </NavLink>
              <NavLink href="/contributor/upload-asset" location={location}>
                Upload Asset
              </NavLink>
              <NavLink href="/contributor/my-library" location={location}>
                My Library
              </NavLink>
              <NavLink
                href="/contributor/transaction-history"
                location={location}
              >
                Transaction History
              </NavLink>
              <NavLink href="/contributor/about-us" location={location}>
                About Us
              </NavLink>
              <NavLink href="/contributor/contact-us" location={location}>
                Contact Us
              </NavLink>
              <NavLink href="/contributor/support" location={location}>
                Support
              </NavLink>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center gap-3 relative">
              <button
                onClick={toggleDropdown}
                className="flex items-center gap-2"
              >
                {userData?.profilePhoto ? (
                  <img
                    src={userData.profilePhoto}
                    alt={userData.fullName}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                ) : (
                  <div className="text-white text-xl font-bold flex items-center justify-center w-10 h-10 rounded-full bg-primary">
                    {userData.fullName ? userData.fullName.charAt(0) : ""}
                  </div>
                )}
                <div className="text-lg">
                  {userData.fullName}
                  {isDropdownOpen ? (
                    <ChevronUp className="inline-block w-4 h-4" />
                  ) : (
                    <ChevronDown className="inline-block w-4 h-4" />
                  )}
                </div>
              </button>

              {isDropdownOpen && (
                <div className="absolute top-14 mt-2 w-40 bg-white shadow-lg rounded-lg z-10">
                  <ul>
                    <li className="w-full hover:bg-gray-100 rounded-t-lg ">
                      <Link
                        to="/contributor/profile"
                        className="block px-4 py-2 text-gray-700 "
                      >
                        Profile Settings
                      </Link>
                    </li>
                    <li className="w-full hover:bg-gray-100 rounded-b-lg">
                      <button
                        onClick={handleLogout}
                        className="block px-4 py-2  text-gray-700 "
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="-mr-2 flex gap-4 items-center sm:hidden">
              <div className="sm:ml-6 sm:flex sm:items-center gap-3 relative">
                <button
                  onClick={toggleDropdown}
                  className="flex items-center gap-2"
                >
                  {userData?.profilePhoto ? (
                    <img
                      src={userData.profilePhoto}
                      alt={userData.fullName}
                      className="w-10 h-10 rounded-full object-cover"
                    />
                  ) : (
                    <div className="text-white text-xl font-bold flex items-center justify-center w-10 h-10 rounded-full bg-primary">
                      {userData.fullName ? userData.fullName.charAt(0) : ""}
                    </div>
                  )}
                  <div className="text-lg">
                    {userData.fullName}
                    {isDropdownOpen ? (
                      <ChevronUp className="inline-block w-4 h-4" />
                    ) : (
                      <ChevronDown className="inline-block w-4 h-4" />
                    )}
                  </div>
                </button>
                {isDropdownOpen && (
                  <div className="absolute top-8 mt-2 w-36 bg-white shadow-lg rounded-lg z-10">
                    <ul>
                      <li className="w-full hover:bg-gray-100 rounded-t-lg ">
                        <Link
                          to="/contributor/profile"
                          className="block px-4 py-2 text-gray-700 "
                        >
                          Profile Settings
                        </Link>
                      </li>
                      <li className="w-full hover:bg-gray-100 rounded-b-lg">
                        <button
                          onClick={handleLogout}
                          className="block px-4 py-2  text-gray-700 "
                        >
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <button className="btn" onClick={toggleMenu}>
                {isOpen ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Full-width mobile menu popup with smooth transition */}
        <div
          className={`fixed inset-0 z-50 transition-opacity duration-1000 ease-in-out ${
            isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <div
            className="absolute inset-0 bg-gray-500 opacity-75"
            onClick={toggleMenu}
          ></div>

          <div
            className={`absolute inset-y-0 right-0 w-full bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${
              isOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8 border-b">
              <Link to="/contributor/dashboard">
                <img src="/assets/images/logo.svg" alt="Logo" width={"70"} />
              </Link>
              <div>
                <button className="btn" onClick={toggleMenu}>
                  <X className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1">
              <MobileNavLink
                href="/contributor/dashboard"
                onClick={toggleMenu}
                location={location}
              >
                Dashboard
              </MobileNavLink>
              <MobileNavLink
                href="/contributor/upload-asset"
                onClick={toggleMenu}
                location={location}
              >
                Upload Asset
              </MobileNavLink>
              <MobileNavLink
                href="/contributor/my-library"
                onClick={toggleMenu}
                location={location}
              >
                My Library
              </MobileNavLink>
              <MobileNavLink
                href="/contributor/transaction-history"
                onClick={toggleMenu}
                location={location}
              >
                Transaction History
              </MobileNavLink>
              <MobileNavLink
                href="/contributor/profile"
                onClick={toggleMenu}
                location={location}
              >
                Profile
              </MobileNavLink>
              <MobileNavLink
                href="/contributor/about-us"
                onClick={toggleMenu}
                location={location}
              >
                About Us
              </MobileNavLink>
              <MobileNavLink
                href="/contributor/contact-us"
                onClick={toggleMenu}
                location={location}
              >
                Contact Us
              </MobileNavLink>
              <MobileNavLink
                href="/contributor/support"
                onClick={toggleMenu}
                location={location}
              >
                Support
              </MobileNavLink>
            </div>
            <div className="mt-4 px-4">
              <Link
                to={"/"}
                className="flex bg-secondary hover:bg-white hover:text-black border-2 border-secondary text-white py-2 px-4 rounded-3xl w-full justify-center"
              >
                Logout
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

function NavLink({ href, children, location }) {
  const isActive = location.pathname === href; // Check if current path matches the href

  return (
    <Link
      to={href}
      className={`inline-flex items-center px-1 pt-1 border-b-2 text-md font-medium ${
        isActive
          ? "text-primary border-primary" // Active link styles
          : "text-gray-700 border-transparent hover:text-gray-900 hover:border-gray-300"
      }`}
    >
      {children}
    </Link>
  );
}

function MobileNavLink({ href, children, onClick, location }) {
  const isActive = location.pathname === href;

  return (
    <Link
      to={href}
      className={`block px-3 py-2 rounded-md text-base font-medium ${
        isActive
          ? "text-primary bg-gray-100" // Active link styles for mobile
          : "text-gray-700 hover:text-gray-900 hover:bg-gray-50"
      }`}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
