import React from "react";
import {
  CrownIcon,
  OneIcon,
  ProcessIcon,
  ThreeIcon,
  TwoIcon,
  UploadIcon,
} from "../../utils/Icons";

function Process() {
  return (
    <div className="my-20 md:px-24 px-5" id="howitworks">
      <h1 className="text-center text-4xl md:text-5xl font-bold mx-auto">
        How <span className="text-primary"> AiNDREA</span> Works
      </h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-20 my-20">
        <div className="lg:mb-0 mb-14">
          <OneIcon />
          <div className="  md:mt-5 mt-10">
            <div className="p-7 bg-white process-one shadow-xl">
              <div className="p-4 bg-primary rounded-2xl w-20 flex justify-center items-center">
                <CrownIcon />
              </div>
              <div className="my-5">
                <h2 className="text-4xl mb-4 font-semibold text-gray-800">
                  Upload
                </h2>
                <p className="text-[#545C59]">
                  Easily upload your digital assets.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-col lg:mb-0 mb-10 items-end md:items-start">
          <div className="mb-5 lg:mt-0 mt-10 bg-gradient-to-r  from-[rgba(110,179,135,0.7)] to-[#DBF4E4] p-[4px] process-two mx-auto">
            <div className="p-7 bg-white  rounded-3xl">
              <div className="p-4 bg-secondary rounded-2xl w-20 flex justify-center items-center">
                <ProcessIcon />
              </div>
              <div className="my-5">
                <h2 className="text-4xl mb-4 font-semibold text-gray-800">
                  Generate NFT
                </h2>
                <p className="text-[#545C59]">
                  Automatically create NFTs for proof of ownership.
                </p>
              </div>
            </div>
          </div>
          <TwoIcon className="md:order-first " />
        </div>

        <div>
          <ThreeIcon />
          <div className="p-[4px] rounded-3xl lg:mt-5 mt-10  bg-gradient-to-r  from-[rgba(110,179,135,0.7)] to-[#DBF4E4]  process-three">
            <div className="p-7 bg-white rounded-3xl">
              <div className="p-4 bg-secondary rounded-2xl w-20 flex justify-center items-center">
                <UploadIcon />
              </div>
              <div className="my-5">
                <h2 className="text-4xl mb-4 font-semibold text-gray-800">
                  Earn
                </h2>
                <p className="text-[#545C59]">
                  Monetize your work through sales, rentals, and subscriptions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex items-center justify-center">
        <button className="flex items-center gap-2 px-6 py-4 rounded-full lg:text-xl  bg-primary text-white hover:border-black  hover:bg-black hover:text-white transition duration-300 ease-in-out">
          Explore the process <ArrowRight />
        </button>
      </div> */}
    </div>
  );
}

export default Process;
