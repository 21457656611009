import React, { useState } from "react";
import EditProduct from "../../components/ContributorsPage/AssetsDetail/EditProduct";
import Navbar from "../../components/ContributorsPage/Navbar";
import Footer from "../../components/ContributorsPage/Footer";
import BreadCrumb from "../../components/common/BreadCrumb";
import { useParams } from "react-router-dom";
function AssetsDetail() {
  const { id } = useParams();
  const [title ,setTitle] = useState("");
  const [category ,setCategory] = useState("");
  return (
    <div>
      <Navbar />
      <BreadCrumb
        img={"/assets/images/assetdetail.png"}
        title={title ||  "Asset Title"}
        subtitle={category || "Asset Category"}
      />
      <div className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
        <EditProduct id={id} setTitle={setTitle} setCategory={setCategory} />
        {/* <Comments /> */}
        {/* <RelatedAssets /> */}
      </div>
      <Footer />
    </div>
  );
}

export default AssetsDetail;
