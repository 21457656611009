import React, { useState } from "react";
import { Search, ChevronDown, Bell, Menu, X, ChevronUp } from "lucide-react";
import { Link } from "react-router-dom";

function CreatorsNavbar() {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-24">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/">
              <img src="/assets/images/logo.svg" alt="Logo" width={"70"} />
            </Link>
          </div>
          {/* Desktop Menu */}
          <div className="hidden lg:flex items-center justify-around flex-1">
            <Link
              to="/marketplace"
              className="text-gray-700 hover:text-gray-900"
            >
              Market Place
            </Link>
            <Link to="/invest" className="text-gray-700 hover:text-gray-900">
              Invest in AiNDREA
            </Link>
            <Link to="/messages" className="text-gray-700 hover:text-gray-900">
              Messages
            </Link>
            {/* More dropdown */}
            <div className="relative inline-block">
              <button
                onClick={toggleDropdown}
                className="text-gray-700 hover:text-gray-900 flex items-center"
              >
                More{" "}
                {isDropdownOpen ? (
                  <ChevronUp className="inline-block w-4 h-4 ml-1" />
                ) : (
                  <ChevronDown className="inline-block w-4 h-4 ml-1" />
                )}
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg z-10">
                  <ul className="py-2">
                    <li>
                      <Link
                        to="/add-assets"
                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      >
                        Add Assets
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/features"
                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      >
                        Features
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact"
                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {/* Search Bar */}
            <div className="relative w-1/3 max-w-xl">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <button className="p-2 border rounded-l-3xl border-primary text-gray-700 hover:text-gray-900 flex items-center">
                  All Items
                  <ChevronDown className="ml-1 w-4 h-4" />
                </button>
              </div>
              <input
                type="text"
                placeholder="Search here..."
                className="w-full pl-28 pr-10 py-2 border border-primary rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary"
              />
              <button className="absolute inset-y-0 rounded-r-3xl right-0 flex items-center px-4 text-white bg-primary hover:bg-black">
                <Search className="w-5 h-5" />
              </button>
            </div>
          </div>
          {/* Icons on Desktop */}
          <div className="hidden lg:flex items-center space-x-3">
            <Link to="/dashboard" className="text-gray-700 hover:text-gray-900">
              Dashboard
            </Link>
            <Link
              to="/creators/mylibrary"
              className="text-gray-700 hover:text-gray-900"
            >
              My Library
            </Link>
            <button className="flex items-center p-2 rounded-[7px] bg-pink-100">
              <Bell className="w-5 h-5 text-primary" />
            </button>
            <div className="text-white text-xl font-bold flex items-center p-1 px-3 rounded-[50%] bg-primary">
              K
            </div>
            <div className="ml-1 text-lg">
              Kane <ChevronDown className="inline-block w-4 h-4" />
            </div>
          </div>
          {/* Mobile Menu Button */}
          <div className="flex items-center lg:hidden">
            <div className="flex items-center">
              <div className="text-white text-xl font-bold flex items-center p-1 px-3 rounded-[50%] bg-primary">
                K
              </div>
              <div className="ml-1 text-lg">
                Kane <ChevronDown className="inline-block w-4 h-4" />
              </div>
              <button className="flex justify-center items-center p-2 rounded-[7px] bg-pink-100 mx-5">
                <Bell className="w-5 h-5 text-primary" />
              </button>
            </div>
            <button
              onClick={toggleMobileMenu}
              className="text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              {isMobileOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      {isMobileOpen && (
        <div className="lg:hidden fixed inset-0 z-50 bg-white transition-opacity duration-1000 ease-in-out">
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8 border-b">
              <div>
                <img src="/assets/images/logo.svg" alt="Logo" width={"70"} />
              </div>
              <div className="flex items-center">
                <div className="text-white text-xl font-bold flex items-center p-1 px-3 rounded-[50%] bg-primary">
                  K
                </div>
                <div className="ml-1 text-lg">
                  Kane <ChevronDown className="inline-block w-4 h-4" />
                </div>
                <button className="flex justify-center items-center p-2 rounded-[7px] bg-pink-100 mx-5">
                  <Bell className="w-5 h-5 text-primary" />
                </button>
                <button className="btn" onClick={() => setIsMobileOpen(false)}>
                  <X className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="flex-1 overflow-y-auto">
              <div className="p-4">
                <div className="relative w-full max-w-xl">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <button className="p-2 border rounded-l-3xl border-primary text-gray-700 hover:text-gray-900 flex items-center">
                      All Items
                      <ChevronDown className="ml-1 w-4 h-4" />
                    </button>
                  </div>
                  <input
                    type="text"
                    placeholder="Search here..."
                    className="w-full rounded-3xl pl-32 pr-10 py-2 border border-primary focus:outline-none focus:ring-2 focus:ring-primary"
                  />
                  <button className="absolute inset-y-0 right-0 flex items-center px-4 text-white bg-primary rounded-r-3xl hover:bg-black">
                    <Search className="w-5 h-5" />
                  </button>
                </div>
                <div className="my-4 space-y-2">
                  <Link
                    to="/dashboard"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    Dashboard
                  </Link>
                  <Link
                    to="/library"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    My Library
                  </Link>
                  <Link
                    to="/marketplace"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    Market Place
                  </Link>
                  <Link
                    to="/invest"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    Invest in AiNDREA
                  </Link>
                  <Link
                    to="/messages"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    Messages
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}

export default CreatorsNavbar;
