import React from "react";
import { Search } from "lucide-react";
import { GreaterThenIcon } from "../../utils/Icons";

function ArticleCard({ imageUrl, title, content }) {
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full">
        <img
          src={imageUrl}
          alt={title}
          className="rounded-3xl object-cover w-full h-[250px]"
        />
      </div>
      <div className="w-full">
        <h2 className="text-2xl font-bold mb-2">{title}</h2>
        <p className="text-gray-600 mb-4">{content}</p>
        <button className="flex items-center gap-2 bg-primary text-white px-4 py-2 rounded-3xl hover:bg-black">
          Read More <GreaterThenIcon />
        </button>
      </div>
    </div>
  );
}

function SearchArticles() {
  const articles = [
    {
      imageUrl: "/assets/images/support/article.png",
      title: "How to Optimize Your Asset Listings",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    },
    {
      imageUrl: "/assets/images/support/article.png",
      title: "Understanding NFT Ownership",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    },
    {
      imageUrl: "/assets/images/support/article.png",
      title: "Maximizing Your Earnings on AiNDREA",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    },
    {
      imageUrl: "/assets/images/support/article.png",
      title: "Setting Up Your Crypto Wallet",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    },
    {
      imageUrl: "/assets/images/support/article.png",
      title: "Navigating the Contributor Dashboard",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    },
  ];

  return (
    <section className="max-w-8xl mx-auto md:py-12 px-4 sm:px-6 lg:px-24">
      <h2 className="md:text-5xl text-2xl font-bold text-center">
        Knowledge <span className="text-primary">Base</span>
      </h2>
      <div className="flex justify-center mt-3 md:mt-7">
        <div className="flex items-center justify-center flex-1">
          <div className="relative w-full max-w-lg mx-auto">
            <input
              type="text"
              placeholder="Search for articles, guides, and tutorials..."
              className="w-full pl-5 pr-10 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary"
            />
            <button className="absolute inset-y-0 right-0 flex items-center px-4 text-white bg-primary hover:bg-black rounded-r-3xl">
              <Search className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:mt-16 mt-10">
        {articles.map((article, index) => (
          <ArticleCard key={index} {...article} />
        ))}
      </div>
    </section>
  );
}

export default SearchArticles;
