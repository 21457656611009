// src/api/openai.js
import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export const generateTitleAndDescription = async (category, asset) => {
  try {
    const prompt = `
    You are an assistant for generating creative titles, descriptions, and tags for various assets.
    A user has uploaded a ${category} asset, and the asset can be found at this URL: ${asset}.
    
    Please generate:
    - A catchy and relevant title, no more than 10 words.
    - A descriptive, engaging description, 1-2 sentences, highlighting the key aspects of the asset and appealing to users interested in ${category}.
    - A set of 5-7 relevant tags that are short and capture the essence of the asset, separated by commas.
    
    Output the response in this format:
    "Title: [Your title here]
    Description: [Your description here]
    Tags: [tag1, tag2, tag3, ...]"
    `;

    const response = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [
        {
          role: "user",
          content: prompt,
        },
      ],
    });

    const text = response.choices[0].message.content;

    // Use regex to parse the title, description, and tags from the response
    const titleMatch = text.match(/Title:\s*(.*)/i);
    const descriptionMatch = text.match(/Description:\s*(.*)/i);
    const tagsMatch = text.match(/Tags:\s*(.*)/i);

    const title = titleMatch ? titleMatch[1].trim() : "Untitled";
    const description = descriptionMatch
      ? descriptionMatch[1].trim()
      : "No description available.";
    const tags = tagsMatch
      ? tagsMatch[1]
          .trim()
          .split(",")
          .map((tag) => tag.trim())
          .join(", ")
      : "";

    return {
      title,
      description,
      tags,
    };
  } catch (error) {
    console.error("Error generating title and description:", error);
    throw error;
  }
};
