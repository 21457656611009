import { Search } from "lucide-react";
import React, { useState, useEffect } from "react";
import TableView from "./TableView";
import GridView from "./GridView";
import { DropdownIcon, ListIcon, ViewIcon } from "../../../utils/Icons";
import { getUserAssets } from "../../../api/Assets";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

function LibraryData() {
  const [viewMode, setViewMode] = useState("grid");
  const token = useSelector((state) => state.userData.token);

  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [sortOption, setSortOption] = useState("Date Uploaded");
  const [category, setCategory] = useState("All");

  useEffect(() => {
    const fetchAssets = async () => {
      setLoading(true);
      try {
        const res = await getUserAssets(token);

        if (res.status === "success") {
          setAssets(res.assets);
          setFilteredAssets(res.assets);
        } else {
          toast.error("Failed to fetch Data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };
    fetchAssets();
    // eslint-disable-next-line
  }, [token]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);

    const filtered = assets.filter((asset) =>
      asset?.title.toLowerCase().includes(value)
    );

    setFilteredAssets(filtered);
  };

  const handleSort = (event) => {
    const selectedOption = event.target.value;
    setSortOption(selectedOption);

    let sortedAssets = [...filteredAssets];

    if (selectedOption === "Title") {
      sortedAssets.sort((a, b) => a.title.localeCompare(b.title));
    } else if (selectedOption === "Price") {
      sortedAssets.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    } else if (selectedOption === "Popularity") {
      sortedAssets = [...assets];
    } else {
      sortedAssets.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    }

    setFilteredAssets(sortedAssets);
  };

  const handleCategoryFilter = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);
    if (selectedCategory === "All") {
      setFilteredAssets(assets);
    } else {
      const filtered = assets.filter(
        (asset) => asset?.category === selectedCategory
      );
      setFilteredAssets(filtered);
    }
  };

  const removeAsset = (assetId) => {
    setAssets((prevAssets) =>
      prevAssets.filter((asset) => asset._id !== assetId)
    );
    setFilteredAssets((prevAssets) =>
      prevAssets.filter((asset) => asset._id !== assetId)
    );
  };

  return (
    <div className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="flex flex-col-reverse sm:flex-row justify-between items-center mb-8">
        <div className="flex space-x-4 mb-5 sm:mb-0">
          <div className="relative flex items-center gap-3 justify-center">
            <h3 className="font-semibold">Filter By:</h3>
            <select
              value={category}
              onChange={handleCategoryFilter}
              className="border rounded-3xl text-md border-primary bg-transparent text-gray-700 p-2 pr-8 focus:outline-none appearance-none"
            >
              <option>All</option>
              <option>Images</option>
              <option>Videos</option>
              <option>Music</option>
              <option>3D Models</option>
              <option>AI Avatars</option>
            </select>
            <div className="absolute right-3 pointer-events-none">
              <DropdownIcon />
            </div>
          </div>

          <div className="relative flex items-center gap-3 justify-center">
            <h3 className="font-semibold">Sort By:</h3>
            <select
              className="border text-md rounded-3xl border-primary bg-transparent text-gray-700 p-2 pr-8 focus:outline-none appearance-none"
              value={sortOption}
              onChange={handleSort}
            >
              <option>Date Uploaded</option>
              <option>Title</option>
              <option>Price</option>
              <option>Popularity</option>
            </select>
            <div className="absolute right-3 pointer-events-none">
              <DropdownIcon />
            </div>
          </div>
        </div>

        <div className="relative w-full sm:max-w-sm mb-5 sm:mb-0">
          <input
            type="text"
            placeholder="Search here..."
            value={searchQuery}
            onChange={handleSearch}
            className="w-full py-2 pl-10 pr-4 border text-md border-primary rounded-3xl focus:outline-none focus:ring-1 focus:ring-primary"
          />
          <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
            <Search className="w-5 h-5 text-primary" />
          </div>
        </div>
      </div>

      <div className="flex justify-end mb-8">
        <div className="flex items-center gap-4">
          <h3 className="font-semibold">View By:</h3>
          <button
            className={`${viewMode === "grid" ? "text-primary" : ""}`}
            onClick={() => setViewMode("grid")}
          >
            <ViewIcon
              size={20}
              color={viewMode === "grid" ? "#6EB387" : "#aaa"}
            />
          </button>

          <button
            className={`${viewMode === "list" ? "text-primary" : ""}`}
            onClick={() => setViewMode("list")}
          >
            <ListIcon
              size={20}
              color={viewMode === "list" ? "#6EB387" : "#aaa"}
            />
          </button>
        </div>
      </div>

      {viewMode === "list" ? (
        <TableView
          loading={loading}
          assets={filteredAssets}
          removeAsset={removeAsset}
        />
      ) : (
        <GridView
          loading={loading}
          assets={filteredAssets}
          removeAsset={removeAsset}
        />
      )}
    </div>
  );
}

export default LibraryData;
