import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";

function Team() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const teamMembers = [
    {
      name: "Jordan Smith",
      role: "Founder & CEO",
      bio: "Jordan is a seasoned entrepreneur with a passion for empowering creators. With a background in blockchain technology and digital marketing, she leads AiNDREA with a vision for a fair and transparent digital marketplace.",
      img: "/assets/images/team/t1.png",
      mt: "md:mt-20",
    },
    {
      name: "Alex Johnson",
      role: "CTO",
      bio: "Alex is the technical backbone of AiNDREA, overseeing all blockchain integrations and platform development. His expertise in smart contracts and decentralized applications ensures our platform remains secure and innovative.",
      img: "/assets/images/team/t2.png",
      mt: "md:mt-52",
    },
    {
      name: "Riley Davisv",
      role: "CMO",
      bio: "Riley spearheads our marketing and community engagement strategies. With extensive experience in digital marketing and community building, she ensures AiNDREA reaches and resonates with its target audience.",
      img: "/assets/images/team/t3.png",
      mt: "md:mt-16",
    },
    {
      name: "Morgan Lee",
      role: "Head of Support",
      bio: "Morgan manages our support team, ensuring that all user inquiries and issues are addressed promptly and effectively. Her dedication to user satisfaction is pivotal to AiNDREA’s success.",
      img: "/assets/images/team/t4.png",
      mt: "md:mt-[-80px]",
    },
  ];

  return (
    <div className="px-5 md:px-24 md:mt-8">
      <h1 className="text-4xl sm:text-5xl font-bold text-center">
        Meet the <span className="text-[#CA63CE]">Team</span>
      </h1>
      <p className="text-gray-700 leading-loose text-lg md:w-[50%] text-center mx-auto mt-3">
        Brief bios of the founding team, highlighting their experience and
        vision for transforming digital content creation & commerce.
      </p>

      {/* Slider for mobile only */}
      {isMobile ? (
        <Slider {...settings} className="mt-10 px-10 md:mb-10 mb-16">
          {teamMembers.map((member, index) => (
            <div key={index} className="px-4">
              <img
                width="100%"
                className="my-3 rounded-2xl"
                src={member.img}
                alt="team member"
              />
              <h1 className="text-2xl font-bold text-center">{member.name}</h1>
              <p className="text-1xl mt-2 text-center text-[#CA63CE]">
                {member.role}
              </p>
              <p className="text-1xl mt-2 text-center">{member.bio}</p>
            </div>
          ))}
        </Slider>
      ) : (
        // Normal grid layout for larger screens
        <div className="grid grid-cols-12 md:gap-12 my-10 px-10">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className={`col-span-12 md:col-span-3 ${member.mt}`}
            >
              <img
                width="100%"
                className="my-3 rounded-2xl"
                src={member.img}
                alt="team member"
              />
              <h1 className="text-2xl font-bold text-center">{member.name}</h1>
              <p className="text-1xl mt-2 text-center text-[#CA63CE]">
                {member.role}
              </p>
              <p className="text-1xl mt-2 text-center">{member.bio}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Team;
