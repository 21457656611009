import React from "react";
import ContactInfo from "./ContactInfo";
import ContactForm from "./ContactForm";

export default function ContactMain() {
  return (
    <section className="py-8">
      <div className=" px-4 sm:px-6 lg:px-24 max-w-8xl mx-auto my-14">
        <h1 className="text-center font-bold md:text-5xl text-2xl my-5 md:mt-8">
          <span className="text-[#CA63CE]">Contact</span> Us
        </h1>
        <div className="border-t-2 border-[#6EB387] mb-5"></div>
        <p className="text-lg text-center mb-3">
          We're here to answer any questions or concerns you may have. Feel free
          to reach out to us!
        </p>
        <div className="grid md:grid-cols-2 grd-cols-1">
          <ContactForm />
          <div className="w-full relative flex md:justify-center">
            <ContactInfo />
          </div>
        </div>
      </div>
    </section>
  );
}
