import React from "react";

function BreadCrumb({ title, subtitle, img }) {
  return (
    <section
      className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "240px",
      }}
    >
      <div className="flex flex-col justify-center  text-left h-full">
        <h2 className="md:text-5xl text-center md:text-start text-4xl font-bold mb-2 text-white">
          {title}
        </h2>
        <p className="md:text-lg lg:text-xl text-center md:text-start text-white mt-2">
          {subtitle}
        </p>
      </div>
    </section>
  );
}

export default BreadCrumb;
