import { Search } from "lucide-react";
import React, { useState } from "react";
import Table from "./Table";
import GridSystem from "./GridSystem";
import {
  CalenderIcon,
  DropdownIcon,
  ListIcon,
  ViewIcon,
} from "../../../utils/Icons";

function LibraryCard() {
  const [viewMode, setViewMode] = useState("grid");

  return (
    <div className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-12">
        <div className="flex space-x-4 mb-5 md:mb-0">
          <div className="relative flex items-center justify-center">
            <div className="absolute left-3 pointer-events-none">
              <CalenderIcon size={20} />
            </div>
            <select className="border rounded-3xl border-primary bg-transparent text-gray-700 pl-10 p-2 text-sm focus:outline-none appearance-none">
              <option>Date</option>
              <option>2020-3-4</option>
              <option>2021-4-2</option>
              <option>2023-9-9</option>
            </select>
            <div className="absolute right-3 pointer-events-none">
              <DropdownIcon />
            </div>
          </div>

          <div className="relative flex items-center justify-center">
            <select className="border rounded-3xl border-primary bg-transparent text-gray-700 p-2 pr-8  text-sm focus:outline-none appearance-none">
              <option>Category</option>
              <option>$100</option>
              <option>$200</option>
              <option>$300</option>
            </select>
            <div className="absolute right-3 pointer-events-none">
              <DropdownIcon />
            </div>
          </div>

          <div className="relative flex items-center justify-center">
            <select className="border rounded-3xl border-primary bg-transparent text-gray-700 p-2 pr-8 text-sm focus:outline-none appearance-none">
              <option>Performance</option>
              <option>Video</option>
              <option>Image</option>
              <option>Template</option>
            </select>
            <div className="absolute right-3 pointer-events-none">
              <DropdownIcon />
            </div>
          </div>
        </div>

        <div className="relative w-full sm:max-w-sm">
          <input
            type="text"
            placeholder="Search here..."
            className="w-full py-2 pl-10 pr-4 border border-primary rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary"
          />
          <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
            <Search className="w-5 h-5 text-primary" />
          </div>
        </div>
      </div>

      <div className="flex justify-between mb-12">
        <div className="flex items-center">
          <label className="mr-3" htmlFor="select-all">
            Select All
          </label>
          <input className="w-5 h-5" type="checkbox" id="select-all" />
        </div>
        <div className="flex items-center">
          <div className="mr-12">View By</div>

          <button
            className={`mr-4 ${viewMode === "grid" ? "text-primary" : ""}`}
            onClick={() => setViewMode("grid")}
          >
            <ViewIcon
              size={28}
              color={viewMode === "grid" ? "#6EB387" : "#aaa"}
            />
          </button>

          <button
            className={`${viewMode === "list" ? "text-primary" : ""}`}
            onClick={() => setViewMode("list")}
          >
            <ListIcon
              size={28}
              color={viewMode === "list" ? "#6EB387" : "#aaa"}
            />
          </button>
        </div>
      </div>

      {viewMode === "list" ? <Table /> : <GridSystem />}
    </div>
  );
}

export default LibraryCard;
