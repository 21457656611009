import React from "react";
import SearchArticles from "../components/SupportPage/SearchArticles";
import SubmitForm from "../components/SupportPage/SubmitForm";
import BreadCrumb from "../components/common/BreadCrumb";
import Navbar from "../components/common/preRegistration/Navbar";
import Footer from "../components/common/preRegistration/Footer";
import FAQSection from "../components/SupportPage/FAQSection";
import MetaTags from "../components/common/MetaTags";

function Support() {
  return (
    <>
      <MetaTags
        title="Support | Aindrea NFT Marketplace"
        description="Get help with using Aindrea! Visit our Support page for FAQs, troubleshooting tips, and resources on managing your NFTs."
        url="/support"
      />

      <Navbar />
      <BreadCrumb title="Support Center" img={"/assets/images/support.png"} />
      <FAQSection />
      <SearchArticles />
      <SubmitForm />
      <Footer />
    </>
  );
}

export default Support;
