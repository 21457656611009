import React from "react";
import ContributerNavbar from "../../components/common/ContributerNavbar";
import Footer from "../../components/common/Footer";
import ProfilePicture from "../../components/ProfileSection/ProfilePicture";
import Products from "../../components/ProfileSection/Products";
import Bio from "../../components/ProfileSection/Bio";

function ProfileSection() {
  return (
    <div>
      <ContributerNavbar />
      <ProfilePicture />
      <Bio />
      <Products />
      <Footer />
    </div>
  );
}

export default ProfileSection;
