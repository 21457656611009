import React from "react";
import { ArrowDown, ArrowUp, Download, Eye, Users } from "lucide-react";

function Stats() {
  return (
    <div className="flex flex-col md:flex-row justify-center gap-x-5">
      <div className="rounded-3xl border border-secondary bg-green-100 inline-flex items-center justify-center py-1 w-full md:w-[20%] lg:w-[15%] mb-8">
        <div className="flex">
          <Download className="mr-5 my-auto text-secondary" />
          <div className="flex flex-col">
            <span>Downloads</span>
            <div className="flex justify-between items-center">
              <span className="font-bold ml-1">254</span>
              <span className="text-xs flex">
                <div className="ms-5">120%</div>
                <ArrowUp className="text-secondary" size={12} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-3xl border border-primary bg-pink-100 inline-flex items-center w-full md:w-[20%] lg:w-[15%] justify-center py-1 mb-8">
        <div className="flex">
          <Eye className="mr-5 my-auto text-primary" />
          <div className="flex flex-col">
            <span>Views</span>
            <div className="flex justify-between items-center">
              <span className="font-bold ml-1">200</span>
              <span className="text-xs flex">
                <div className="ms-5">-20%</div>
                <ArrowDown className="text-primary" size={12} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-3xl border border-primary bg-pink-100 inline-flex items-center w-full md:w-[20%] lg:w-[15%] justify-center py-1 mb-8">
        <div className="flex">
          <Users className="mr-5 my-auto text-primary" />
          <div className="flex flex-col">
            <span>Clients</span>
            <div className="flex justify-between items-center">
              <span className="font-bold ml-1">180</span>
              <span className="text-xs flex">
                <div className="ms-5">-10%</div>
                <ArrowDown className="text-primary" size={12} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats;
