import React from "react";
import UploadFile from "../../components/ContributorsPage/UploadAssets/UploadFile";
import Navbar from "../../components/ContributorsPage/Navbar";
import BreadCrumb from "../../components/common/BreadCrumb";
import Footer from "../../components/ContributorsPage/Footer";

function UploadAssets() {
  return (
    <div>
      <Navbar />
      <BreadCrumb
        img={"../assets/images/assetimg.png"}
        title={"Upload New Asset"}
        subtitle={"Share your creativity with the world and earn rewards."}
      />
      <UploadFile />
      <Footer />
    </div>
  );
}

export default UploadAssets;
