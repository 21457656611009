import React from "react";
import BreadCrumb from "../common/BreadCrumb";
import WhoWeAre from "../AboutUsPage/WhoWeAre";
import Team from "../AboutUsPage/Team";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function AboutUsCont() {
  return (
    <>
      <Navbar />
      <BreadCrumb
        title="About Us"
        img={"/assets/images/aboutus/about-us.png"}
      />
      <WhoWeAre />
      <Team />
      <Footer />
    </>
  );
}
