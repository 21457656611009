import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { Play, Pause } from "lucide-react";

export default function Music({ data }) {
  const containerRef = useRef(null);
  const waveSurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!containerRef.current) return;

    // Create a new WaveSurfer instance
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      waveColor: "#999999",
      progressColor: "#666666",
      cursorWidth: 0,
      barWidth: 2,
      barGap: 1,
      height: 64,
      barRadius: 0,
      normalize: true,
      responsive: true,
    });

    waveSurfer.load(data);
    waveSurferRef.current = waveSurfer;

    waveSurfer.on("play", () => setIsPlaying(true));
    waveSurfer.on("pause", () => setIsPlaying(false));

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.unAll();
        waveSurferRef.current.destroy();
        waveSurferRef.current = null;
      }
    };
  }, [data]);

  const handlePlayPause = () => {
    if (waveSurferRef.current) {
      waveSurferRef.current.playPause();
    }
  };

  return (
    <div className="flex items-center gap-4 w-full bg-pink-50 rounded-3xl p-4 py-10 h-full">
      <button
        onClick={handlePlayPause}
        className="flex items-center justify-center w-12 h-12 rounded-full bg-black text-white hover:bg-gray-800 transition-colors"
        aria-label={isPlaying ? "Pause" : "Play"}
      >
        {isPlaying ? <Pause size={24} /> : <Play size={24} className="ml-1" />}
      </button>
      <div ref={containerRef} className="flex-1 [&_wave]:rounded-none z-50" />
    </div>
  );
}
