import React from 'react'
import NavbarAuth from '../components/common/NavbarAuth'
import Form2 from '../components/ForgetPassword/Form2'

function ForgetPassword1() {
    return (
        <div>
            <NavbarAuth />
            <Form2 />
        </div>
    )
}

export default ForgetPassword1
