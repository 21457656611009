import React from "react";
import SaleGraph from "../../components/Analytics/SaleGraph";
import ContributerNavbar from "../../components/common/ContributerNavbar";
import Footer from "../../components/common/Footer";
import CategoryGraph from "../../components/Analytics/CategoryGraph";
import AssetGraph from "../../components/Analytics/AssetGraph";
import UsageGraph from "../../components/Analytics/UsageGraph";
import EarningGraph from "../../components/Analytics/EarningGraph";
import CategoryTable from "../../components/Analytics/CategoryTable";
import Stats from "../../components/Analytics/Stats";
import ContributerBreadCrumb from "../../components/common/ContributerBreadCrumb";

function Analytics() {
  return (
    <>
      <ContributerNavbar />
      <ContributerBreadCrumb
        img={"../assets/images/analytics.png"}
        title={"Analytics"}
        subtitle={"Lorem ipsum dolor sit amet, consectetur adipiscing"}
      />
      <div className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-x-20 justify-center">
          <div className="col-span-12 md:col-span-7">
            <SaleGraph />
          </div>
          <div className="col-span-12 md:col-span-5">
            <EarningGraph />
          </div>
          <div className="col-span-12">
            <Stats />
          </div>
          <div className="col-span-12 md:col-span-6">
            <CategoryGraph />
          </div>
          <div className="col-span-12 md:col-span-6 my-auto">
            <CategoryTable />
          </div>
          <div className="col-span-12 md:col-span-6">
            <AssetGraph />
          </div>
          <div className="col-span-12 md:col-span-6 my-auto">
            <CategoryTable />
          </div>
          <div className="col-span-12 md:col-span-6">
            <UsageGraph />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Analytics;
