import React from 'react'
import Form from '../components/Register/Form'
import NavbarAuth from '../components/common/NavbarAuth'
function Register() {
  return (
    <div>
      <NavbarAuth/>
      <Form/>
    </div>
  )
}

export default Register
