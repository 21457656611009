import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { getLatestUserAssets, deleteUserAsset } from "../../../api/Assets";
import moment from "moment";
import { Trash2 } from "lucide-react";
import ButtonLoader from "../../common/ButtonLoader";
import Image from "../Previews/Image";
import ThreedModel from "../Previews/3dModel";
import Video from "../Previews/Video";
import Music from "../Previews/Music";
import DeleteConfirmationModal from "../MyLibrary/DeleteConfirmationModal";
import { EditIcon, EyeIcon } from "../../../utils/Icons";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

export default function RecentActivities() {
  const token = useSelector((state) => state.userData.token);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [delLoadingId, setDelLoadingId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);

  // Define the removeAsset function
  const removeAsset = (assetId) => {
    setAssets((prevAssets) =>
      prevAssets.filter((asset) => asset._id !== assetId)
    );
  };

  useEffect(() => {
    const fetchAssets = async () => {
      setLoading(true);
      try {
        const res = await getLatestUserAssets(token);
        if (res.status === "success") {
          setAssets(res.assets);
        } else {
          toast.error("Failed to fetch Data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };
    fetchAssets();
  }, [token]);

  const handleDelete = async () => {
    setDelLoadingId(selectedAssetId);
    try {
      const result = await deleteUserAsset(selectedAssetId, token);
      if (result && result.status === "success") {
        toast.success(result.message || "Deleted Successfully!");
        removeAsset(selectedAssetId);
      } else {
        toast.error(result.message || "Failed to Delete.");
      }
    } catch (error) {
      toast.error("An error occurred during Delete.");
    } finally {
      setDelLoadingId(null);
      setShowDeleteModal(false);
    }
  };

  const openDeleteModal = (assetId) => {
    setSelectedAssetId(assetId);
    setShowDeleteModal(true);
  };

  if (loading) {
    return (
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-24">
        <div className="font-semibold md:text-3xl text-2xl mb-4">
          Recent Activities
        </div>
        <div className="text-gray-500 w-full flex items-center gap-6">
          <div className="text-primary border-b-2 border-primary hover:cursor-pointer">
            All Uploads
          </div>
          <div>Purchased</div>
          <div>Rented</div>
        </div>
        <div className="my-8">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border-collapse">
              <thead>
                <tr className="text-gray-600 uppercase text-md leading-normal">
                  <th className="py-3 px-6 text-md text-left border-b">
                    Items Uploaded
                  </th>
                  <th className="py-3 px-6 text-md text-left border-b">
                    Category
                  </th>
                  <th className="py-3 px-6 text-md text-left border-b">
                    Title
                  </th>
                  <th className="py-3 px-6 text-md text-left border-b">Date</th>
                  <th className="py-3 px-6 text-md text-left border-b">
                    Price
                  </th>
                  <th className="py-3 px-6 text-md text-left border-b">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-700 text-md">
                {Array(4)
                  .fill()
                  .map((_, idx) => (
                    <tr className="border-b" key={idx}>
                      <td className="py-3">
                        <Skeleton height={60} width={100} />
                      </td>
                      <td className="py-3 px-6">
                        <Skeleton width={100} />
                      </td>
                      <td className="py-3 px-6">
                        <Skeleton width={80} />
                      </td>
                      <td className="py-3 px-6">
                        <Skeleton width={100} />
                      </td>
                      <td className="py-3 px-6">
                        <Skeleton width={60} />
                      </td>
                      <td className="py-3 px-6">
                        <div className="flex gap-2">
                          <Skeleton height={30} width={30} />
                          <Skeleton height={30} width={30} />
                          <Skeleton height={30} width={30} />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  if (assets.length === 0) {
    return (
      <div>
        <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-24">
          <div className="font-semibold md:text-3xl text-2xl mb-4">
            Recent Activities
          </div>
          <div className="text-gray-500 w-full flex items-center gap-6">
            <div className="text-primary border-b-2 border-primary hover:cursor-pointer">
              All Uploads
            </div>
            <div>Purchased</div>
            <div>Rented</div>
          </div>
          <div className="my-8">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border-collapse">
                <thead>
                  <tr className="text-gray-600 uppercase text-md leading-normal">
                    <th className="py-3 px-6 text-md text-left border-b">
                      Items Uploaded
                    </th>
                    <th className="py-3 px-6 text-md text-left border-b">
                      Category
                    </th>
                    <th className="py-3 px-6 text-md text-left border-b">
                      Title
                    </th>
                    <th className="py-3 px-6 text-md text-left border-b">
                      Date
                    </th>
                    <th className="py-3 px-6 text-md text-left border-b">
                      Price
                    </th>
                    <th className="py-3 px-6 text-md text-left border-b">
                      Action
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="flex justify-center items-center h-[50vh]">
            <p>No assets found.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-24">
      <div className="font-semibold md:text-3xl text-2xl mb-4">
        Recent Activities
      </div>
      <div className="text-gray-500 w-full flex items-center gap-6">
        <div className="text-primary border-b-2 border-primary hover:cursor-pointer">
          All Uploads
        </div>
        <div>Purchased</div>
        <div>Rented</div>
      </div>
      <div className="my-8">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border-collapse">
            <thead>
              <tr className="text-gray-600 uppercase text-md leading-normal">
                <th className="py-3 px-6 text-md text-left border-b">
                  Items Uploaded
                </th>
                <th className="py-3 px-6 text-md text-left border-b">
                  Category
                </th>
                <th className="py-3 px-6 text-md text-left border-b">Title</th>
                <th className="py-3 px-6 text-md text-left border-b">Date</th>
                <th className="py-3 px-6 text-md text-left border-b">Price</th>
                <th className="py-3 px-6 text-md text-left border-b">Action</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-md">
              {assets.map((asset) => (
                <tr className="border-b" key={asset?._id}>
                  <td className="py-3">
                    <div className="w-[125px] h-[100px] rounded-3xl">
                      {(() => {
                        switch (asset?.category) {
                          case "Images":
                            return (
                              <Image data={asset?.file} alt={asset?.title} />
                            );
                          case "3D Models":
                            return (
                              <ThreedModel
                                data={asset?.file}
                                alt={asset?.title}
                              />
                            );
                          case "Videos":
                            return <Video data={asset?.file} />;
                          case "Music":
                            return <Music data={asset?.file} />;
                          default:
                            return "Unable to Preview";
                        }
                      })()}
                    </div>
                  </td>
                  <td className="py-3 px-6">{asset?.category}</td>
                  <td className="py-3 px-6">{asset?.title}</td>
                  <td className="py-3 px-6">
                    {moment(asset?.createdAt).format("DD MMMM, YYYY")}
                  </td>
                  <td className="py-3 px-6">${asset?.price.toFixed(2)}</td>
                  <td>
                    <div className="flex gap-2">
                      <Link
                        to={`/contributor/asset/${asset._id}`}
                        className="bg-[#fbf5fb] p-1 rounded"
                      >
                        <EditIcon size={18} color="#CA63CE" />
                      </Link>
                      <Link
                        to={`/contributor/asset/${asset._id}`}
                        className="bg-green-100 p-1 rounded mx-1"
                      >
                        <EyeIcon size={18} color="#6EB387" />
                      </Link>
                      <button
                        onClick={() => openDeleteModal(asset._id)}
                        className="bg-red-100 p-1 rounded"
                        disabled={delLoadingId === asset._id}
                      >
                        {delLoadingId === asset._id ? (
                          <ButtonLoader />
                        ) : (
                          <Trash2 size={18} />
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteConfirmationModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
          loading={delLoadingId}
        />
      )}
    </div>
  );
}
