import React, { useCallback, useState } from "react";
import { CloudUpload } from "lucide-react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";

function Upload({
  credentials,
  setCredentials,
  setLoading,
  fileName,
  setFileName,
  fileSize,
  setFileSize,
}) {
  const [uploadProgress, setUploadProgress] = useState(0);

  // Accepted file types as MIME types or extensions.
  const getAcceptedFileTypes = () => {
    switch (credentials.category) {
      case "Images":
        return {
          "image/*": [],
        };
      case "Videos":
        return {
          "video/*": [],
        };
      case "Music":
        return {
          "audio/*": [],
        };
      case "3D Models":
        return {
          "model/*": [".glb"],
        };
      case "AI Avatars":
        return {
          "image/*": [],
          // "video/*": [],
        };
      case "Others":
        return {
          "*/*": [],
        };
      default:
        return {};
    }
  };

  // Handles file drop and validation.
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!credentials.category) {
        toast.error("Please select a category before uploading a file.");
        return;
      }

      const file = acceptedFiles[0];
      if (file) {
        setFileName(file.name);
        setFileSize((file.size / (1024 * 1024)).toFixed(2)); // Convert to MB with 2 decimal places.
        setUploadProgress(0);
        uploadFile(file);
      }
    },
    //eslint-disable-next-line
    [credentials.category]
  );

  // Upload file with progress tracking.
  const uploadFile = (file) => {
    setLoading(true);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${process.env.REACT_APP_API_URL}/upload/single`); // Replace with your actual upload endpoint.

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 99.9;
        setUploadProgress(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const jsonResponse = JSON.parse(xhr.response);
        toast.success("File uploaded successfully!");
        setCredentials((prev) => ({
          ...prev,
          file: jsonResponse.data,
        }));
        setUploadProgress(100);
        setLoading(false);
      } else {
        toast.error("Failed to upload file.");
        setUploadProgress(0);
        setLoading(false);
      }
    };

    xhr.onerror = () => {
      toast.error("An error occurred during the file upload.");
      setUploadProgress(0);
      setLoading(false);
    };

    const formData = new FormData();
    formData.append("file", file);
    xhr.send(formData);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: getAcceptedFileTypes(),
    multiple: false,
    disabled: !credentials.category,
  });

  return (
    <div className="mt-2">
      <div
        {...getRootProps()}
        className={`bg-pink-50 w-full h-80 flex flex-col justify-center items-center border border-primary rounded-xl ${
          isDragActive ? "bg-pink-100" : ""
        } ${!credentials.category ? "opacity-50 cursor-not-allowed" : ""}`}
      >
        <input {...getInputProps()} disabled={!credentials.category} />
        <CloudUpload size={72} color="#CA63CE" />
        <div className="text-gray-500">
          {isDragActive
            ? "Drop the file here ..."
            : credentials.category
            ? "Drag and drop your file here or click to select"
            : "Select a category to enable file upload"}
        </div>
      </div>

      {(fileName || credentials.file) && (
        <div className="bg-green-50 w-full h-32 md:h-28 flex flex-col justify-center border border-secondary rounded-xl mt-6">
          <div className="p-2 mx-3">
            <div className="text-gray-700 font-bold mb-2">
              {fileName || credentials.file}
            </div>
            <div className="flex justify-between items-center">
              <div className="w-[80%] md:w-[93%] bg-gray-200 rounded-full h-4">
                <div
                  className="bg-secondary h-4 rounded-full"
                  style={{
                    width: `${
                      credentials.file ? 100 : Math.round(uploadProgress)
                    }%`,
                  }}
                />
              </div>
              <div className="text-lg text-gray-700">
                {credentials.file ? "100.0" : uploadProgress.toFixed(1)}%
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-gray-500 mt-2">{fileSize} MB</div>
              <div className="text-gray-800 font-semibold mt-2 ms-3">
                {credentials.file
                  ? "Asset Uploaded Successfully"
                  : "Uploading Asset..."}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Upload;
