import React from "react";
import ButtonLoader from "../../common/ButtonLoader";

function AccountSetting({ userData, handleChange, handleUpdate, loading }) {
  return (
    <form onSubmit={handleUpdate}>
      <div className="flex md:gap-10 gap-3 mt-7">
        <div className="w-full ">
          <div>
            <label
              htmlFor="fullName "
              className="font-semibold text-black text-opacity-70 "
            >
              Full Name
            </label>
            <input
              id="fullName"
              type="text"
              placeholder="First Name"
              className=" border-[1px] border-primary shadow-sm  p-3 rounded-xl w-full  outline-black mt-2"
              required
              name="fullName"
              value={userData?.fullName}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="flex md:gap-10 gap-3 mt-4">
        <div className="w-1/2 ">
          <div>
            <label
              htmlFor="phonenumber "
              className="font-semibold text-black text-opacity-70 "
            >
              Phone Number
            </label>
            <input
              id="phoneNumber"
              type="tel"
              placeholder="Phone Number"
              className=" border-[1px] border-primary shadow-sm  p-3 rounded-xl w-full  outline-black mt-2"
              required
              name="phoneNumber"
              value={userData?.phoneNumber}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="w-1/2 ">
          <div>
            <label
              htmlFor="email"
              className="font-semibold text-black text-opacity-70 "
            >
              Email Address
            </label>
            <input
              id="email"
              type="email"
              placeholder="Email Address"
              className=" border-[1px] border-primary shadow-sm  p-3 rounded-xl w-full  outline-black mt-2"
              required
              name="email"
              value={userData?.email}
              onChange={handleChange}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="flex md:gap-10 gap-3 mt-4">
        <div className="w-1/2 ">
          <div>
            <label
              htmlFor="city "
              className="font-semibold text-black text-opacity-70 "
            >
              City
            </label>
            <input
              id="city"
              type="text"
              placeholder="Your Area"
              className=" border-[1px] border-primary shadow-sm  p-3 rounded-xl w-full  outline-black mt-2"
              required
              name="city"
              value={userData?.city}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="w-1/2 ">
          <div>
            <label
              htmlFor="country"
              className="font-semibold text-black text-opacity-70 "
            >
              Country
            </label>
            <input
              id="country"
              type="text"
              placeholder="Country"
              className=" border-[1px] border-primary shadow-sm  p-3 rounded-xl w-full  outline-black mt-2"
              required
              name="country"
              value={userData?.country}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="w-full">
          <div>
            <label
              htmlFor="postCode "
              className="font-semibold text-black text-opacity-70 "
            >
              Postcode
            </label>
            <input
              id="postCode"
              type="text"
              placeholder="00000 e.g"
              className=" border-[1px] border-primary shadow-sm  p-3 rounded-xl w-full  outline-black mt-2"
              required
              name="postCode"
              value={userData?.postCode}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="w-full">
          <label
            htmlFor="description "
            className="font-semibold text-black text-opacity-70"
          >
            Description
          </label>
          <textarea
            id="description"
            type="text"
            placeholder="Write Text....t"
            className="border-[1px] border-primary  p-4 rounded-xl w-full  outline-black mt-2"
            required
            rows={3}
            name="description"
            value={userData?.description}
            onChange={handleChange}
          />
        </div>
        <div className="mt-4 md:w-1/4">
          <button
            type="submit"
            className="w-full font-semibold  bg-primary text-white py-3 rounded-3xl hover:bg-black"
          >
            {loading ? <ButtonLoader /> : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
}

export default AccountSetting;
