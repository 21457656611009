import React, { useState } from "react";
import { Info } from "lucide-react";
import Upload from "./Upload";
import Preview from "./Preview";
import ButtonLoader from "../../common/ButtonLoader";
import toast from "react-hot-toast";
import { uploadAssets } from "../../../api/Assets";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { generateTitleAndDescription } from "../../../api/OpenAi";

function UploadFile() {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [credentials, setCredentials] = useState({
    title: "",
    description: "",
    category: "",
    licenceType: "",
    price: "",
    tags: "",
    file: "",
    nft: true,
  });
  const [step, setStep] = useState(1);
  const [generating, setGenerating] = useState(false);
  const token = useSelector((state) => state.userData.token);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNext = async () => {
    if (!credentials.category || !credentials.file) return;
    setGenerating(true);
    try {
      const { title, description, tags } = await generateTitleAndDescription(
        credentials.category,
        credentials.file
      );

      setCredentials((prev) => ({
        ...prev,
        title,
        description,
        tags,
      }));
      setStep(2);
    } catch (error) {
      toast.error("Failed to generate title and description.");
    } finally {
      setGenerating(false);
    }
  };

  const handleAssets = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await uploadAssets({ ...credentials }, token);
      if (result && result.status === "success") {
        setCredentials({
          title: "",
          description: "",
          category: "",
          licenceType: "",
          price: "",
          tags: "",
          file: "",
        });
        toast.success(result.message || "Asset successfully Uploaded!");
        navigate("/contributor/my-library");
      } else {
        toast.error(result.message || "Failed to Upload the Asset.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      {step === 1 && (
        <div className="md:w-[70%] mx-auto">
          <div className="mb-5">
            <label htmlFor="category" className="font-bold">
              Category
            </label>
            <select
              className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none mt-2 h-[48px]"
              name="category"
              id="category"
              value={credentials.category}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Category
              </option>
              <option value="Images">Images</option>
              <option value="Videos">Videos</option>
              <option value="Music">Music</option>
              <option value="3D Models">3D Models</option>
              <option value="AI Avatars">AI Avatars</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div className="w-100">
            <div>
              <label htmlFor="category" className="font-bold">
                Choose Asset
              </label>
              <Upload
                credentials={credentials}
                setCredentials={setCredentials}
                setLoading={setLoading}
                setFileName={setFileName}
                fileName={fileName}
                setFileSize={setFileSize}
                fileSize={fileSize}
              />
            </div>
            <div className="flex justify-between mt-5 col-span-2">
              <button
                type="button"
                className="w-full mr-2 bg-primary rounded-3xl py-3 text-white hover:bg-black"
                onClick={handleNext}
                disabled={
                  !credentials.category || !credentials.file || generating
                }
              >
                {generating ? <ButtonLoader /> : "Next"}
              </button>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
          <form onSubmit={handleAssets}>
            <div className="md:w-[70%] mx-auto">
              <div className="ml-0 lg:ml-10">
                <label htmlFor="title" className="font-bold">
                  Preview Your Asset
                </label>
                <Preview data={credentials} />

                <div className="mb-4">
                  <label htmlFor="title" className="font-bold">
                    Asset Title
                  </label>
                  <input
                    id="title"
                    type="text"
                    placeholder="Enter the title of your asset"
                    className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none mt-2"
                    required
                    value={credentials.title}
                    onChange={handleChange}
                    name="title"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="description" className="font-bold">
                    Description
                  </label>
                  <textarea
                    id="description"
                    type="text"
                    placeholder="Provide a detailed description of your asset"
                    className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none mt-2"
                    rows={3}
                    required
                    value={credentials.description}
                    onChange={handleChange}
                    name="description"
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="price" className="font-bold">
                    Price ($)
                  </label>
                  <input
                    id="price"
                    type="number"
                    placeholder="Enter the price of your asset"
                    className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none mt-2"
                    required
                    value={credentials.price}
                    onChange={handleChange}
                    name="price"
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="tags" className="font-bold">
                    Tags
                  </label>
                  <input
                    id="tags"
                    type="text"
                    placeholder="e.g., nature, abstract, music"
                    className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none mt-2"
                    required
                    value={credentials.tags}
                    onChange={handleChange}
                    name="tags"
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="licenceType" className="font-bold">
                    Licence Type
                  </label>
                  <select
                    className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none mt-2 h-[48px]"
                    name="licenceType"
                    id="licenceType"
                    value={credentials.licenceType}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Select License Type
                    </option>
                    <option value="Royalty-Free">Royalty-Free</option>
                    <option value="Exclusive">Exclusive</option>
                    {/* <option value="Non-Exclusive">Non-Exclusive</option> */}
                  </select>
                </div>
                <div className="flex items-center justify-end space-x-3 mb-5">
                  <input
                    id="terms"
                    type="checkbox"
                    name="nft"
                    checked={credentials.nft}
                    className="appearance-none bg-[#F6FAF6] rounded-xl border outline-2 w-4 h-4 checked:bg-primary checked:border checked:ring-2 checked:ring-offset-2 checked:ring-primary"
                  />
                  <label
                    htmlFor="terms"
                    className="hover:text-secondarytext-black cursor-pointer"
                  >
                    <div className="flex gap-3 items-center">
                      <span>Generate NFT</span>
                      <span className="relative group">
                        <Info className="text-primary" size={18} />
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-sm py-2 px-4 rounded-lg shadow-lg">
                          Check to create an NFT for proof of ownership
                        </div>
                      </span>
                    </div>
                  </label>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5">
                  <button
                    type="submit"
                    className="w-full bg-primary rounded-3xl py-3 text-white hover:bg-black"
                  >
                    {loading ? (
                      <ButtonLoader />
                    ) : (
                      "Publish Asset and Generate NFT"
                    )}
                  </button>
                  <button
                    type="button"
                    className="w-full bg-gray-500 rounded-3xl py-3 text-white hover:bg-gray-700 sm:order-first"
                    onClick={() => setStep(1)}
                  >
                    Back
                  </button>
                </div>
              </div>

              <div></div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default UploadFile;
