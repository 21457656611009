import React from "react";
import Navbar from "../../components/ContributorsPage/Navbar";
import Footer from "../../components/ContributorsPage/Footer";
import BreadCrumb from "../../components/common/BreadCrumb";
import TransactionTable from "../../components/ContributorsPage/TransactionHistory/TransactionTable";
import EarningSummary from "../../components/ContributorsPage/TransactionHistory/EarningSummary";

function TransationHistory() {
  return (
    <>
      <Navbar />
      <BreadCrumb
        img={"/assets/images/transaction.png"}
        title={"Transaction History"}
        subtitle={"Review your earnings and transaction activities."}
      />
      <EarningSummary/>
      <TransactionTable />
      <Footer />
    </>
  );
}

export default TransationHistory;
