import React from "react";
import Image from "../Previews/Image";
import Video from "../Previews/Video";
import Music from "../Previews/Music";
import ThreedModel from "../Previews/3dModel";
import Avatar from "../Previews/Avatar";

function Preview({ data }) {
  if (!data.file) {
    return (
      <div className="bg-pink-50 w-full h-80 mt-2 rounded-xl border border-primary flex flex-col justify-center items-center">
        Waiting For Live Preview
      </div>
    );
  }
  
  return (
    <div className="w-full h-80 mt-2 flex flex-col justify-center items-center">
      {/* Preview for Images */}
      {data.category === "Images" && (
        <div className="bg-pink-50 w-auto h-[320px] my-2 rounded-xl border border-primary flex flex-col justify-center items-center">
          <Image data={data?.file} />
        </div>
      )}

      {/* Preview for Videos */}
      {data.category === "Videos" && (
        <div className="bg-pink-50 w-auto h-[320px] my-2 rounded-xl border border-primary flex flex-col justify-center items-center">
          <Video data={data?.file} />
        </div>
      )}

      {/* Preview for Audio */}
      {data.category === "Music" && (
        <div className="bg-pink-50 w-full h-[320px] my-2 rounded-xl border border-primary flex flex-col justify-center items-center">
          <Music data={data?.file} />
        </div>
      )}

      {/* Preview for 3D Models */}
      {data.category === "3D Models" && (
        <div className="bg-pink-50 w-full h-[320px] my-2 rounded-xl border border-primary flex flex-col justify-center items-center">
          <ThreedModel data={data?.file} />
        </div>
      )}

      {/* Preview for AI Avatars */}
      {data.category === "AI Avatars" && (
        <div className="bg-pink-50 w-full h-[320px] my-2 rounded-xl border border-primary flex flex-col justify-center items-center">
          <Avatar data={data?.file} />
        </div>
      )}
    </div>
  );
}

export default Preview;
