import { X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { PreRegisterUser } from "../../api/preRegisterApi";
import ButtonLoader from "../common/ButtonLoader";
import toast from "react-hot-toast";

function PreRegisterModal({ setIsOpen, setShowSuccessModal, type = "" }) {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: type,
    industry: "",
    organization: "",
    investmentInterest: "",
    experience: "",
    terms: false,
  });

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: inputType === "checkbox" ? checked : value,
    }));
  };

  const handleRoleChange = (role) => {
    setCredentials((prev) => ({
      ...prev,
      role,
    }));
  };

  const validateForm = () => {
    const {
      firstName,
      lastName,
      email,
      role,
      industry,
      investmentInterest,
      experience,
      terms,
    } = credentials;

    if (!role) {
      toast.error("Please select a role (Collaborator or Investor).");
      return false;
    }

    if (!firstName) {
      toast.error("First name is required.");
      return false;
    }

    if (!lastName) {
      toast.error("Last name is required.");
      return false;
    }

    if (!email || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      toast.error("A valid email address is required.");
      return false;
    }

    if (role === "Collaborator" && !industry) {
      toast.error("Industry/Field is required for Collaborators.");
      return false;
    }

    if (role === "Investor" && (!investmentInterest || !experience)) {
      toast.error(
        "Investment Interests and Experience Level are required for Investors."
      );
      return false;
    }

    if (!terms) {
      toast.error("You must agree to the Terms of Service.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    const response = await PreRegisterUser(credentials);
    if (response.status === "success") {
      // Open success modal instead of toast
      setIsOpen(false);
      setShowSuccessModal(true);
      setLoading(false);
    } else if (response.status === "error") {
      toast.error("You have Already Pre-Registered");
      setLoading(false);
    } else {
      toast.error("Something went wrong, Please try again after few minutes");
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center">
      {/* Modal background overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal content */}
      <div className="relative bg-white w-full max-w-6xl md:h-auto h-full rounded-lg shadow-lg z-50 overflow-y-auto">
        <img
          src="/assets/images/preregister-bg.png"
          alt="BG"
          className="absolute bottom-0 md:max-w-[40%] max-w-[100%] -z-10"
        />
        <div className="h-16 flex justify-between md:mx-8 mx-3">
          <img src="/assets/images/logo.svg" alt="Logo" width={70} />
          <button
            onClick={() => setIsOpen(false)}
            className="p-2 text-gray-500 hover:text-gray-700"
          >
            <X className="h-6 w-6" />
            <span className="sr-only">Close</span>
          </button>
        </div>
        <div className="grid md:grid-cols-2 gap-8 p-5">
          {/* Left section */}
          <div className="space-y-6 md:mx-8 mx-3 relative hidden md:block">
            <div className="text-primary font-medium">Get in Touch</div>
            <h2 className="text-4xl md:text-5xl font-bold">
              Don't Miss Out! Join AiNDREA Today
            </h2>
            <p className="text-gray-600">
              Be among the first to experience AiNDREA and take your digital
              design career to new heights. Pre-registered users will receive
              free tokens when they activate their accounts post-launch.
            </p>
          </div>

          {/* Right section */}
          <div className="flex items-center md:mx-8 mx-3">
            <div className="w-full max-w-md mx-auto">
              <h3 className="text-2xl font-semibold mb-4">Pre-Register Now</h3>
              <p className="text-gray-600">
                Stay updated with us and get more chances for registration.
              </p>
              <hr className="my-6" />
              <form onSubmit={handleSubmit} className="space-y-4">
                {/* Role Selection */}
                <div className="space-y-2">
                  <label htmlFor="preRegisterAs">Pre-Register as:</label>
                  <div className="grid grid-cols-2 gap-4">
                    <button
                      type="button"
                      className={`p-2 w-full text-white hover:bg-primary rounded-3xl ${credentials.role === "Collaborator"
                        ? "bg-primary"
                        : "bg-gray-400"
                        }`}
                      onClick={() => handleRoleChange("Collaborator")}
                    >
                      Collaborator
                    </button>
                    <button
                      type="button"
                      className={`p-2 w-full text-white hover:bg-primary rounded-3xl ${credentials.role === "Investor"
                        ? "bg-primary"
                        : "bg-gray-400"
                        }`}
                      onClick={() => handleRoleChange("Investor")}
                    >
                      Investor
                    </button>
                  </div>
                </div>

                {/* First Name and Last Name Fields */}
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      id="firstName"
                      name="firstName"
                      value={credentials.firstName}
                      onChange={handleChange}
                      placeholder="First name"
                      className="bg-purple-50 p-2 rounded w-full outline-primary"
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      id="lastName"
                      name="lastName"
                      value={credentials.lastName}
                      onChange={handleChange}
                      placeholder="Last name"
                      className="bg-purple-50 p-2 rounded w-full outline-primary"
                      required
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label htmlFor="email">Email Address</label>
                  <input
                    id="email"
                    name="email"
                    value={credentials.email}
                    onChange={handleChange}
                    type="email"
                    placeholder="Email Address"
                    className="bg-purple-50 p-2 rounded w-full outline-primary"
                    required
                  />
                </div>

                {/* Conditionally rendered fields based on role selection */}
                {credentials.role === "Collaborator" && (
                  <>
                    <div className="space-y-2">
                      <label htmlFor="industry">Industry/Field</label>
                      <input
                        id="industry"
                        name="industry"
                        value={credentials.industry}
                        onChange={handleChange}
                        placeholder="Select your industry/field"
                        className="bg-purple-50 p-2 rounded w-full outline-primary"
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="organization">
                        Company/Organization (optional)
                      </label>
                      <input
                        id="organization"
                        name="organization"
                        value={credentials.organization}
                        onChange={handleChange}
                        placeholder="Enter your company or organization name"
                        className="bg-purple-50 p-2 rounded w-full outline-primary"
                      />
                    </div>
                  </>
                )}

                {credentials.role === "Investor" && (
                  <>
                    <div className="space-y-2">
                      <label htmlFor="investmentInterest">
                        Investment Interests
                      </label>
                      <input
                        id="investmentInterest"
                        name="investmentInterest"
                        value={credentials.investmentInterest}
                        onChange={handleChange}
                        placeholder="Select your investment interests"
                        className="bg-purple-50 p-2 rounded w-full outline-primary"
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="experience">Experience Level</label>
                      <input
                        id="experience"
                        name="experience"
                        value={credentials.experience}
                        onChange={handleChange}
                        placeholder="Select your experience level"
                        className="bg-purple-50 p-2 rounded w-full outline-primary"
                        required
                      />
                    </div>
                  </>
                )}

                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="terms"
                    checked={credentials.terms}
                    onChange={handleChange}
                    className="appearance-none h-4 w-4 shadow-lg rounded-full bg-purple-50 border border-gray-300 checked:bg-primary checked:border-[##CA63CE4D] focus:outline-none"
                    id="term"
                    required
                  />
                  <label htmlFor="term" className="cursor-pointer">
                    I agree to the Terms of Service and Privacy Policy.
                  </label>
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full bg-primary hover:bg-black transition duration-300 ease-in-out text-white py-2 rounded-full"
                >
                  {loading ? <ButtonLoader /> : "Submit"}
                </button>
                <br />
                <br />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreRegisterModal;
