import {
  ArrowRight,
  ArrowUp,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
} from "lucide-react";
import React from "react";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation(); // Get the current route

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const isActiveLink = (path) => location.pathname === path; // Check if the link is active

  return (
    <>
      <footer className="md:pt-16 pt-8 border-t">
        <div
          className="px-5 md:px-24 pb-16"
          style={{
            backgroundImage: 'url("/assets/images/footer.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Logo and Social Media */}
            <div>
              <img
                src="/assets/images/logo.svg"
                alt="Logo"
                width={80}
                height={80}
              />
              <h3 className="font-bold text-lg mb-2 mt-4">Follow Us on:</h3>
              <div className="flex space-x-4">
                <Facebook className="w-6 h-6 text-primary hover:text-black" />
                <Twitter className="w-6 h-6 text-primary hover:text-black" />
                <Instagram className="w-6 h-6 text-primary hover:text-black" />
                <Linkedin className="w-6 h-6 text-primary hover:text-black" />
              </div>
            </div>

            {/* Links */}
            <div>
              <h3 className="font-bold mb-4 text-lg">Quick Links</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/contributor/dashboard"
                    className={`${
                      isActiveLink("/contributor/dashboard")
                        ? "text-primary"
                        : "text-gray-600"
                    } hover:text-primary`}
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contributor/upload-asset"
                    className={`${
                      isActiveLink("/contributor/upload-asset")
                        ? "text-primary"
                        : "text-gray-600"
                    } hover:text-primary`}
                  >
                    Upload Asset
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contributor/my-library"
                    className={`${
                      isActiveLink("/contributor/my-library")
                        ? "text-primary"
                        : "text-gray-600"
                    } hover:text-primary`}
                  >
                    My Library
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contributor/transaction-history"
                    className={`${
                      isActiveLink("/contributor/transaction-history")
                        ? "text-primary"
                        : "text-gray-600"
                    } hover:text-primary`}
                  >
                    Transaction History
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contributor/about-us"
                    className={`${
                      isActiveLink("/contributor/about-us")
                        ? "text-primary"
                        : "text-gray-600"
                    } hover:text-primary`}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contributor/contact-us"
                    className={`${
                      isActiveLink("/contributor/contact-us")
                        ? "text-primary"
                        : "text-gray-600"
                    } hover:text-primary`}
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contributor/support"
                    className={`${
                      isActiveLink("/contributor/support")
                        ? "text-primary"
                        : "text-gray-600"
                    } hover:text-primary`}
                  >
                    Support
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="font-bold text-lg mb-4">Legal Links</h3>
              <ul className="space-y-2 mb-8">
                <li>
                  <Link
                    to="/"
                    className={`${
                      isActiveLink("/") ? "text-primary" : "text-gray-600"
                    } hover:text-primary`}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    className={`${
                      isActiveLink("/") ? "text-primary" : "text-gray-600"
                    } hover:text-primary`}
                  >
                    Terms and Conditions
                  </Link>
                </li>
              </ul>
            </div>

            {/* Additional Resources and Newsletter */}
            <div>
              <h3 className="font-bold text-lg mb-2">
                Newsletter <span className="text-primary">Subscription</span>
              </h3>
              <p className="text-sm text-gray-600 mb-10">
                Stay updated on the latest from AiNDREA. Subscribe to our
                newsletter.
              </p>
              <div className="flex mb-4">
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="flex-grow px-4 py-2 rounded-l-full bg-purple-50 focus:outline-none"
                />
                <button className="bg-primary hover:bg-black transition duration-300 ease-in-out text-white px-4 py-2 rounded-r-full">
                  <ArrowRight className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Copyright */}
        <div className="py-2 flex w-full px-5 bg-[rgba(202,99,206,0.15)]">
          <div className="flex items-center ms-auto">
            <p className="text-sm text-gray-900">
              Copyrights © 2024 All Rights Reserved by AiNDREA.
            </p>
          </div>
          <button
            onClick={scrollTop}
            className="bg-primary hover:bg-black ms-auto text-white p-2 rounded-full transition duration-300 ease-in-out"
          >
            <ArrowUp className="w-5 h-5" />
          </button>
        </div>
      </footer>
    </>
  );
}

export default Footer;
