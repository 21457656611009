import { useState, useEffect } from "react";
import { Menu, X } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import PreRegisterModal from "../../PreRegistrationPage/PreRegisterModal";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <nav className="bg-white border-b">
        <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-24">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/">
                  <img src="/assets/images/logo.svg" alt="Logo" width={"70"} />
                </Link>
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              <NavLink href="/" isActive={isActive("/")}>
                Home
              </NavLink>
              <NavLink href="/about-us" isActive={isActive("/about-us")}>
                About Us
              </NavLink>
              <NavLink href="/contact-us" isActive={isActive("/contact-us")}>
                Contact Us
              </NavLink>
              <NavLink href="/support" isActive={isActive("/support")}>
                Support
              </NavLink>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center gap-3">
              <Link
                to={"/login"}
                className="bg-secondary hover:bg-white hover:text-black border-2 border-secondary text-white py-2 px-4 rounded-3xl"
              >
                Log In
              </Link>
              <Link
                to={"/signup"}
                className="bg-[#fff] text-black border-2 border-black py-2 px-4 hover:bg-black hover:text-white rounded-3xl"
              >
                Join as Contributor
              </Link>
            </div>
            <div className="-mr-2 flex items-center sm:hidden">
              <button className="btn" onClick={toggleMenu}>
                {isOpen ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Full-width mobile menu popup with smooth transition */}
        <div
          className={`fixed inset-0 z-50 transition-opacity duration-1000 ease-in-out ${
            isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <div
            className="absolute inset-0 bg-gray-500 opacity-75"
            onClick={toggleMenu}
          ></div>
          <div
            className={`absolute inset-y-0 right-0 w-full bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${
              isOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8 border-b">
              <div>
                <img src="/assets/images/logo.svg" alt="Logo" width={"70"} />
              </div>
              <button className="btn" onClick={toggleMenu}>
                <X className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1">
              <MobileNavLink href="/" isActive={isActive("/")} onClick={toggleMenu}>
                Home
              </MobileNavLink>
              <MobileNavLink href="/about-us" isActive={isActive("/about-us")} onClick={toggleMenu}>
                About Us
              </MobileNavLink>
              <MobileNavLink href="/contact-us" isActive={isActive("/contact-us")} onClick={toggleMenu}>
                Contact Us
              </MobileNavLink>
              <MobileNavLink href="/support" isActive={isActive("/support")} onClick={toggleMenu}>
                Support
              </MobileNavLink>
            </div>
            <div className="mt-4 px-4">
              <Link
                to={"/login"}
                className="flex bg-secondary hover:bg-white hover:text-black border-2 border-secondary text-white py-2 px-4 rounded-3xl w-full justify-center"
              >
                Log In
              </Link>
            </div>
            <div className="mt-4 px-4">
              <Link
                to={"/signup"}
                className="flex bg-[#fff] text-black border-2 border-black py-2 px-4 hover:bg-black hover:text-white rounded-3xl w-full justify-center"
              >
                Join as Contributor
              </Link>
            </div>
          </div>
        </div>
      </nav>
      {isModalOpen && <PreRegisterModal setIsOpen={setIsModalOpen} />}
    </>
  );
}

function NavLink({ href, children, isActive }) {
  return (
    <Link
      to={href}
      className={`inline-flex items-center px-1 pt-1 border-b-2 ${
        isActive ? "text-primary border-primary" : "border-transparent text-gray-700"
      } text-md font-medium hover:text-gray-900 hover:border-gray-300`}
    >
      {children}
    </Link>
  );
}

function MobileNavLink({ href, children, onClick, isActive }) {
  return (
    <Link
      to={href}
      className={`block px-3 py-2 rounded-md text-base font-medium ${
        isActive ? "text-primary" : "text-gray-700"
      } hover:text-gray-900 hover:bg-gray-50`}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
