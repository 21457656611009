import React from "react";
import { BinIcon, EditIcon, EyeIcon } from "../../../utils/Icons";

function Card() {
  return (
    <div className="bg-white shadow-sm shadow-primary rounded-3xl overflow-hidden">
      <div className="relative p-4">
        <div className="relative">
          <img
            className="w-full h-54 object-cover rounded-3xl"
            src="/assets/images/CreatorsPages/MyLibrary/card.png"
            alt="Card Images"
          />
          <div className="absolute top-4 left-4">
            <input
              type="checkbox"
              className="text-secondary focus:ring-secondary border-gray-300 rounded"
            />
          </div>
          <div className="absolute top-4 right-4 flex flex-col space-y-2">
            <div className="bg-white p-1 rounded">
              <EditIcon size={14} />
            </div>
            <div className="bg-white p-1 rounded">
              <EyeIcon size={14} />
            </div>
            <div className="bg-white p-1 rounded">
              <BinIcon size={14} />
            </div>
          </div>
        </div>
      </div>

      <div className="p-4">
        <div className="mb-2 flex justify-between">
          <div className="font-medium">Title</div>
          <div className="text-[#636363]">Logo</div>
        </div>
        <div className="mb-2 flex justify-between">
          <div className="font-medium">Category</div>
          <div className="text-[#636363]">Photo</div>
        </div>
        <div className="mb-2 flex justify-between">
          <div className="font-medium">Upload</div>
          <div className="text-[#636363]">2023-7-8</div>
        </div>
        <div className="mb-2 flex justify-between">
          <div className="font-medium">Performance</div>
          <div className="text-[#636363]">Good</div>
        </div>
        <div className="mb-2 flex justify-between">
          <div className="font-medium">NFT Status</div>
          <div className="text-secondary">Completed</div>
        </div>
      </div>
    </div>
  );
}

export default Card;
