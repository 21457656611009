import { ArrowRightLeft, DollarSign, Upload } from "lucide-react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userStats } from "../../../api/User";

export default function Stats() {
  const userData = useSelector((state) => state.userData.user);
  const token = useSelector((state) => state.userData.token);
  const [stats, getStats] = useState({});
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const res = await userStats(token);

        if (res.status === "success") {
          getStats(res.data);
        } else {
          toast.error("Failed to fetch Data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching data.");
      } finally {
      }
    };
    fetchStats();
  }, [token]);
  console.log(stats);
  return (
    <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="flex flex-row justify-between items-center mb-8">
        <h2 className="md:text-4xl text-2xl font-bold">
          Welcome, <span className="text-primary">{userData.fullName}</span>
        </h2>

        <div className="mt-4 md:mt-0 gap-4 hidden md:flex">
          <Link
            to={"/contributor/upload-asset"}
            className="flex bg-secondary hover:bg-white hover:text-black border-2 border-secondary text-white py-2 px-4 rounded-3xl"
          >
            Upload New Asset
          </Link>
          <Link
            to={"/contributor/my-library"}
            className="bg-primary hover:bg-black text-white rounded-3xl px-4 py-2"
          >
            View My Library
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="rounded-3xl border border-primary bg-pink-100 inline-flex items-center ps-6 py-5">
          <div className="flex items-center gap-6">
            <Upload size={48} className="my-auto text-primary" />
            <div className="flex flex-col">
              <span className="text-xl font-semibold">
                Total Assets Uploaded
              </span>
              <div className="flex items-center">
                <span className="font-normal text-2xl">{stats?.totalAssets || 0}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-3xl border border-primary bg-pink-100 inline-flex items-center ps-6 py-5">
          <div className="flex items-center gap-6">
            <DollarSign size={48} className="my-auto text-primary" />
            <div className="flex flex-col">
              <span className="text-xl font-semibold">Total Earnings</span>
              <div className="flex items-center">
                <span className="font-normal text-2xl">
                  <span className="text-xl">$</span>{stats?.totalEarnings || 0}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-3xl border border-primary bg-pink-100 inline-flex items-center ps-6 py-5">
          <div className="flex items-center gap-6">
            <ArrowRightLeft size={48} className="my-auto text-primary" />
            <div className="flex flex-col">
              <span className="text-xl font-semibold">Recent Transactions</span>
              <div className="flex items-center">
                <span className="font-normal text-2xl">{stats?.recentTransactions || 0}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
