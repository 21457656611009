import React from "react";

function Messages() {
  return (
    <div>
      {/* Messages Content */}
      <p>Messages content goes here...</p>
    </div>
  );
}

export default Messages;
