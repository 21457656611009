import React from "react";
import Form from "../components/Login/Form";
import NavbarAuth from "../components/common/NavbarAuth";

function Login() {
  return (
    <div>
      <NavbarAuth />
      <Form />
    </div>
  );
}

export default Login;
