import { DollarSign } from "lucide-react";
import React from "react";

export default function EarningSummary() {
  return (
    <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="flex flex-row justify-between items-center mb-8">
        <h2 className="md:text-3xl text-2xl font-semibold">Earning Summary</h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="rounded-3xl border border-primary bg-pink-100 inline-flex items-center ps-6 py-5">
          <div className="flex items-center gap-6">
            <DollarSign size={48} className="my-auto text-primary" />
            <div className="flex flex-col">
              <span className="text-xl font-semibold">Total Earnings</span>
              <div className="flex items-center">
                <span className="font-normal text-xl">
                  {/* <span className="text-xl">$</span>200,000 */}
                  Coming Soon
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-3xl border border-primary bg-pink-100 inline-flex items-center ps-6 py-5">
          <div className="flex items-center gap-6">
            <DollarSign size={48} className="my-auto text-primary" />
            <div className="flex flex-col">
              <span className="text-xl font-semibold">Monthly Earnings</span>
              <div className="flex items-center">
                <span className="font-normal text-xl">
                  {/* <span className="text-xl">$</span>200,000 */}
                  Coming Soon
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-3xl border border-primary bg-pink-100 inline-flex items-center ps-6 py-5">
          <div className="flex items-center gap-6">
            <DollarSign size={48} className="my-auto text-primary" />
            <div className="flex flex-col">
              <span className="text-xl font-semibold">Yearly Earnings</span>
              <div className="flex items-center">
                <span className="font-normal text-xl">
                  {/* <span className="text-xl">$</span>200,000 */}
                  Coming Soon
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
