import React, { useEffect, useState } from "react";
import { ArrowIcon, BubbleIcon } from "../../utils/Icons";
import PreRegisterModal from "./PreRegisterModal";
import { Link } from "react-router-dom";
import PreRegisterSuccessModal from "./PreRegisterSuccessModal";

function Hero() {
  const [isOpen, setIsOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div
        id="hero"
        className="relative overflow-hidden min-h-[95vh] flex items-center justify-center bg-gradient-to-r from-white via-white to-[rgba(202,99,206,0.2)]"
        style={
          isMobile
            ? {
                backgroundImage: 'url("/assets/images/mbl-herobg.png")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }
            : {}
        }
      >
        {/* Background decorations */}
        <div className="absolute hidden md:block top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-circular-clockwise">
          <BubbleIcon size={105} />
        </div>

        <div className="absolute hidden md:block bottom-[280px] left-[320px] animate-circular-counterclockwise">
          <BubbleIcon size={120} />
        </div>

        <div className="absolute hidden md:block bottom-10 right-40 animate-circular-zigzag">
          <BubbleIcon size={205} />
        </div>

        <div className="px-6 md:px-24">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center relative">
            <img
              src="assets/images/ball.png"
              alt="ball"
              className="absolute w-[80px] top-[-120px] md:hidden transform -translate-x-1/2 -translate-y-1/2 animate-circular-clockwise"
            />
            <img
              src="assets/images/ball.png"
              alt="ball"
              className="absolute w-[120px] top-[100px] right-0 md:hidden animate-circular-counterclockwise"
            />

            <div className="space-y-16">
              <h1 className="text-5xl sm:text-6xl font-bold">
                Empower Your
                <span className="py-2 block bg-[linear-gradient(93.33deg,_rgba(202,_99,_206,_0.7)_6.24%,_rgba(163,_138,_217,_0.867107)_50.65%,_#6EB387_85.98%)] bg-clip-text text-transparent">
                  Creativity with
                </span>
                AiNDREA
              </h1>
              <p className="text-xl text-gray-600">
                The Ultimate Web3 Marketplace for Digital Assets
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <Link to={"/signup"} className="button text-lg min-w-[46%]">
                  Join as Contributor
                  <ArrowIcon size={24} />
                </Link>
                <button
                  onClick={() => setIsOpen(true)}
                  className="px-8 py-3 rounded-full text-lg border-2 border-black text-gray-700 hover:bg-black hover:text-white transition duration-300 ease-in-out"
                >
                  Pre-register as a Collaborator or Investor
                </button>
              </div>
            </div>

            {/* Right column: Images */}
            <div className="relative hidden sm:block mt-4 ">
              <img
                src="/assets/images/hero.png"
                className="h-auto ms-auto w-[85%] animate-bounce-slow"
                alt="Hero"
              />
            </div>
          </div>
        </div>
      </div>
      {isOpen && <PreRegisterModal setIsOpen={setIsOpen} setShowSuccessModal={setShowSuccessModal} />}
      {showSuccessModal && (
        <PreRegisterSuccessModal setIsOpen={setShowSuccessModal} />
      )}
    </>
  );
}

export default Hero;
