import { useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import React, { useState, useEffect } from "react";
import { updatePassword } from "../../api/authApi";
import toast from "react-hot-toast";
import ButtonLoader from "../common/ButtonLoader";

function Form() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const storedData = localStorage.getItem("forgetPasswordData");
    if (!storedData) {
      navigate("/enter-otp");
    } else {
      const parsedData = JSON.parse(storedData);
      if (parsedData.status === false) {
        navigate("/enter-otp");
      } else {
        setToken(parsedData.token); 
      }
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (credentials.password < 8) {
      toast.error("Password must be greater then 8");
      return;
    }

    if (credentials.password !== credentials.confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    setLoading(true);
    try {
      const result = await updatePassword( {password: credentials.password}, token );
      if (result && result.status === "success") {
        toast.success(result.message || "Password changed successfully!");
        localStorage.removeItem("forgetPasswordData");
        navigate("/login");
      } else {
        toast.error(result.message || "Failed to update password.");
      }
    } catch (error) {
      toast.error("An error occurred during password update.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="flex flex-col md:flex-row items-center gap-8">
        <div className="w-full md:w-1/2 order-2">
          <div className="my-auto">
            <h2 className="text-2xl md:text-5xl text-gray-800 font-semibold mb-4">
              Change Password
            </h2>
            <form onSubmit={handleUpdatePassword}>
              <div className="grid grid-cols-1 gap-4 my-6">
                <div className="space-y-3">
                  <label
                    htmlFor="new-password"
                    className="font-medium text-lg  text-black text-opacity-70 mb-0"
                  >
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      id="new-password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Create a password"
                      className="bg-[#F6FAF6] p-3 rounded-xl w-full border-none outline-none pr-10"
                      required
                      onChange={handleChange}
                      value={credentials.password}
                    />
                    <span
                      className="absolute inset-y-0 right-4 flex items-center cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <EyeOff size={20} className="text-secondary" />
                      ) : (
                        <Eye size={20} className="text-secondary" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="space-y-3">
                  <label
                    htmlFor="confirm-password"
                    className="font-medium text-lg  text-black text-opacity-70 mb-0"
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      id="confirm-password"
                      name="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm your password"
                      className="bg-[#F6FAF6] p-3 rounded-xl w-full border-none outline-none pr-10"
                      required
                      onChange={handleChange}
                      value={credentials.confirmPassword}
                    />
                    <span
                      className="absolute inset-y-0 right-4 flex items-center cursor-pointer"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? (
                        <EyeOff size={20} className="text-secondary" />
                      ) : (
                        <Eye size={20} className="text-secondary" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="space-y-3">
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full bg-secondary hover:bg-black transition duration-300 ease-in-out text-white py-2 rounded-full"
                >
                  {loading ? <ButtonLoader /> : "Change Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full md:w-1/2 order-1">
          <div className="w-full flex items-center justify-center">
            <img
              src="/assets/images/forcreators/getstarted.png"
              alt="Get Started"
              className="rounded-3xl object-cover md:w-[90%] w-[100%]"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Form;
