import React, { useState } from "react";
import { GreaterDownthanIcon, GreaterGreaterthanIcon } from "../../utils/Icons";

function FAQSection() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Collapse if clicked again
  };

  return (
    <section className="max-w-8xl mx-auto py-12 md:mt-8 px-4 sm:px-6 lg:px-24">
      <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
        <div className="order-2">
          <div className="w-full">
            {[
              // Array of accordion items for easier mapping
              {
                question: "How do I upload an asset?",
                answer:
                  "To upload an asset, navigate to your dashboard and click on the 'Upload Asset' button. Fill in the required details and submit your asset for publishing.",
              },
              {
                question: "How does NFT generation work?",
                answer:
                  "When you upload an asset and select the 'Generate NFT' option, our system automatically creates an NFT using blockchain technology, providing you with proof of ownership.",
              },
              {
                question: "How can I track my earnings?",
                answer:
                  "You can track your earnings by visiting the 'Transaction History' page in your dashboard, where all sales and rental transactions are recorded.",
              },
              {
                question: "How do I reset my password?",
                answer:
                  "If you've forgotten your password, click on the 'Forgot Password?' link on the login page and follow the instructions to reset it.",
              },
              {
                question: "How can I contact support?",
                answer:
                  "For further assistance, please fill out the contact form on our 'Contact Us' page or reach out via our support email at support@aindrea.com.",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="accordion-item rounded-3xl bg-primary mb-5"
              >
                <h2 className="accordion-header">
                  <button
                    className={`flex justify-between items-center w-full text-left py-4 px-6 md:text-lg text-sm rounded-3xl ${
                      activeIndex === index
                        ? "border-t rounded-3xl border-primary text-white"
                        : "bg-[#F3F6F9]"
                    }`}
                    onClick={() => toggleAccordion(index)}
                  >
                    {item.question}
                    {activeIndex === index ? (
                      <GreaterDownthanIcon />
                    ) : (
                      <GreaterGreaterthanIcon />
                    )}
                  </button>
                </h2>
                <div
                  className={`baccordion-body transition-all duration-500 overflow-hidden ${
                    activeIndex === index
                      ? "max-h-screen py-4 px-6"
                      : "max-h-0 px-6"
                  }`}
                >
                  <p className="text-white md:text-md text-sm">{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="order-1 flex flex-col">
          <h2 className="font-bold md:text-5xl text-2xl me-auto">
            Frequently <span className="text-primary">Asked</span> Questions
          </h2>
          <img className="mx-auto hidden md:block" width={'50%'} src="/assets/images/arrow-pink.png" alt="arrow" />
        </div>
      </div>
    </section>
  );
}

export default FAQSection;
