// Dynamic Headers
export function createHeaders(userToken = "", contentType) {
  let headers = {
    // Default headers
    "auth-token": userToken,
    "Access-Control-Allow-Origin": "*",
  };

  if (contentType === "json") {
    headers["Content-Type"] = "application/json";
  } else if (contentType === "urlencoded") {
    headers["Content-Type"] = "application/x-www-form-urlencoded";
  }

  return headers;
}
