import React from "react";

export default function Image({ data, alt = "Preview" }) {
  return (
    <div className="flex items-center w-full rounded-3xl bg-pink-50 h-full">
      <img
        src={data}
        alt={alt}
        className="w-full h-full object-contain rounded-lg"
        draggable="false"
      />
    </div>
  );
}
