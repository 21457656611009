import React from "react";
import LibraryData from "../../components/ContributorsPage/MyLibrary/LibraryData";
import BreadCrumb from "../../components/common/BreadCrumb";
import Navbar from "../../components/ContributorsPage/Navbar";
import Footer from "../../components/ContributorsPage/Footer";

function MyLibrary() {
  return (
    <div>
      <Navbar />
      <BreadCrumb
        img={"../assets/images/library.png"}
        title={"My Library"}
        subtitle={"Manage your digital assets with ease."}
      />
      <LibraryData />
      <Footer />
    </div>
  );
}

export default MyLibrary;
