import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import PreRegisterModal from "../PreRegistrationPage/PreRegisterModal";
import PreRegisterSuccessModal from "../PreRegistrationPage/PreRegisterSuccessModal";
import { getUserData, userLogin } from "../../api/authApi";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../../redux/actions/userActions";
import ButtonLoader from "../common/ButtonLoader";

function Form() {
  const [isCollaboratorOpen, setIsCollaboratorOpen] = useState(false);
  const [isInvestorOpen, setIsInvestorOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: inputType === "checkbox" ? checked : value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const result = await userLogin({ ...credentials });
      if (result && result.status === "success") {
        const userData = await getUserData(result.data);
        const token = await result.data;
        toast.success(result.message || "Login successful!");
        dispatch(setToken(token));
        dispatch(setUser(userData.data));
        navigate("/contributor/dashboard");
      } else {
        toast.error(result.message || "Failed to Login.");
      }
    } catch (error) {
      toast.error("An error occurred during login.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
        <div className="flex flex-col md:flex-row items-center gap-8">
          <div className="w-full md:w-1/2 order-2">
            <div className="my-auto">
              <h2 className="text-2xl md:text-5xl text-gray-800 font-semibold mb-4">
                Log In to AiNDREA
              </h2>
              <form onSubmit={handleLogin}>
                <div className="grid grid-cols-1 gap-4 my-6">
                  <div className="space-y-3">
                    <label
                      for="email"
                      className="font-medium text-lg  text-black text-opacity-70 mb-0"
                    >
                      Email Address
                    </label>
                    <input
                      id="email"
                      type="email"
                      placeholder="Enter your Email Address"
                      className="bg-[#F6FAF6] p-3 rounded-xl w-full border-none outline-none"
                      required
                      value={credentials.email}
                      onChange={handleChange}
                      name="email"
                    />
                  </div>

                  <div className="space-y-3">
                    <label
                      for="password"
                      className="font-medium text-lg  text-black text-opacity-70 mb-0"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Create a password"
                        className="bg-[#F6FAF6] p-3 rounded-xl w-full border-none outline-none pr-10"
                        required
                        value={credentials.password}
                        onChange={handleChange}
                        name="password"
                      />
                      <span
                        className="absolute inset-y-0 right-4 flex items-center cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <EyeOff size={20} className="text-secondary" />
                        ) : (
                          <Eye size={20} className="text-secondary" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="text-right me-2 text-md  hover:text-primary">
                    <Link to="/forgot-password">Forget Your Password?</Link>
                  </div>
                </div>

                <div className="space-y-3">
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full bg-secondary hover:bg-black transition duration-300 ease-in-out text-white py-2 rounded-full"
                  >
                    {loading ? <ButtonLoader /> : "Login"}
                  </button>
                </div>
              </form>

              <div className="flex items-center justify-center my-4 mx-32">
                <hr
                  className="flex-grow"
                  style={{
                    background:
                      "linear-gradient(93deg, rgba(202, 99, 206, 0.70) 6.24%, rgba(163, 138, 217, 0.87) 50.65%, #6EB387 85.98%)",
                    height: "1px",
                    border: "none",
                  }}
                />
                <span className="px-2">OR</span>
                <hr
                  className="flex-grow"
                  style={{
                    background:
                      "linear-gradient(93deg, rgba(202, 99, 206, 0.70) 6.24%, rgba(163, 138, 217, 0.87) 50.65%, #6EB387 85.98%)",
                    height: "1px",
                    border: "none",
                  }}
                />
              </div>

              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <button
                    onClick={() => setIsCollaboratorOpen(true)}
                    className="bg-white font-semibold hover:text-white hover:bg-secondary border-2 border-secondary rounded-3xl text-black w-full p-3"
                  >
                    Pre-register as Collaborator
                  </button>
                  <button
                    onClick={() => setIsInvestorOpen(true)}
                    className="bg-white font-semibold hover:text-white hover:bg-secondary border-2 border-secondary rounded-3xl text-black w-full p-3"
                  >
                    Pre-register as Investor
                  </button>
                </div>
              </div>

              {/* <div className="flex items-center justify-center my-4 mx-32">
              <hr
                className="flex-grow"
                style={{
                  background:
                    "linear-gradient(93deg, rgba(202, 99, 206, 0.70) 6.24%, rgba(163, 138, 217, 0.87) 50.65%, #6EB387 85.98%)",
                  height: "1px",
                  border: "none",
                }}
              />
              <span className="px-4 text-gray-600">or</span>
              <hr
                className="flex-grow"
                style={{
                  background:
                    "linear-gradient(93deg, rgba(202, 99, 206, 0.70) 6.24%, rgba(163, 138, 217, 0.87) 50.65%, #6EB387 85.98%)",
                  height: "1px",
                  border: "none",
                }}
              />
            </div>

            <div className="space-y-3">
              <button className="flex items-center justify-center bg-[#F6FAF6] rounded-xl w-full p-3 space-x-2">
                <GoogleBIcon />
                <span>Continue With Google</span>
              </button>

              <button className="flex items-center justify-center bg-[#F6FAF6] rounded-xl w-full p-3 space-x-2">
                <AppleBIcon />
                <span>Continue With Apple</span>
              </button>
              <button className="flex items-center justify-center bg-[#F6FAF6] rounded-xl w-full p-3 space-x-2">
                <FacebookBIcon />
                <span>Continue With Facebook</span>
              </button>
            </div> */}
            </div>
          </div>

          <div className="w-full md:w-1/2 order-1 hidden md:block">
            <div className="w-full flex items-center justify-center">
              <img
                src="/assets/images/forcreators/getstarted.png"
                alt="Get Started"
                className="rounded-3xl object-cover md:w-[90%] w-[100%]"
              />
            </div>
          </div>
        </div>
      </section>
      {isCollaboratorOpen && (
        <PreRegisterModal
          type={"Collaborator"}
          setIsOpen={setIsCollaboratorOpen}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
      {isInvestorOpen && (
        <PreRegisterModal
          type={"Investor"}
          setIsOpen={setIsInvestorOpen}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
      {showSuccessModal && (
        <PreRegisterSuccessModal setIsOpen={setShowSuccessModal} />
      )}
    </>
  );
}

export default Form;
