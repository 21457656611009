import React from "react";
import Card from "./Card";

function GridSystem() {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>
      <div className="flex justify-center items-center mt-7">
        <button className="w-full md:w-1/5 bg-primary hover:bg-black transition duration-300 ease-in-out text-white py-3 rounded-full">
          View More
        </button>
      </div>
    </>
  );
}

export default GridSystem;
