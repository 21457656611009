import { post, get, del, put} from "./apiRequests";

//Upload Assets
export async function uploadAssets(credentials,userToken) {
  try {
    const response = await post("asset/create", credentials, userToken);
    return response;
  } catch (error) {}
}


//Get Assets by User Token 
export async function getUserAssets(userToken) {
  try {
    const response = await get("asset/getAllByToken", userToken);
    
    return response;
  } catch (error) {}
}

//Get Latest Assets by User Token 
export async function getLatestUserAssets(userToken) {
  try {
    const response = await get("assets/latest", userToken);
    
    return response;
  } catch (error) {}
}

//Get Assets by ID 
export async function getAssetsbyId(id , userToken) {
  try {
    const response = await get(`asset/get/${id}` , userToken);
    
    return response;
  } catch (error) {}
}

//Update Assets by ID 
export async function updateAssetsbyId(id ,assets, userToken) {
  try {
    const response = await put(`asset/update/${id}`,assets , userToken);
    return response;
  } catch (error) {}
}

//Delete Assets by ID 
export async function deleteUserAsset(id, userToken) {
  try {
    const response = await del(`asset/delete/${id}`, userToken);
    
    return response;
  } catch (error) {}
}