import { Camera } from "lucide-react";
import React, { useState } from "react";

function ProfilePicture() {
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);

  const handleCoverFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setSelectedCoverImage(imageURL);
    }
  };

  const handleProfileFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setSelectedProfileImage(imageURL);
    }
  };

  const handleCoverEditClick = () => {
    document.getElementById("coverFileInput").click();
  };

  const handleProfileEditClick = () => {
    document.getElementById("profileFileInput").click();
  };

  return (
    <div className="relative">
      <img
        src={selectedCoverImage || "../assets/images/cover.png"}
        alt="Cover"
        className="w-full h-64 object-cover"
      />

      <input
        type="file"
        id="coverFileInput"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleCoverFileChange}
      />

      <div className="absolute bottom-[-10px] left-[5%] transform translate-y-1/2">
        <img
          src={selectedProfileImage || "../assets/images/homepage/cont4.png"} 
          alt="Profile"
          className="w-48 h-48 rounded-full border-4 border-primary object-cover"
        />

        <input
          type="file"
          id="profileFileInput"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleProfileFileChange}
        />

        <button
          className="absolute bg-white rounded-full p-2 shadow-md hover:bg-gray-100 focus:outline-none transition bottom-12 right-4 transform translate-x-1/2 translate-y-1/2"
          onClick={handleProfileEditClick}
        >
          <Camera />
        </button>
      </div>

      <div className="absolute bottom-48 right-4">
        <button
          className="bg-white rounded-full p-2 flex shadow-md hover:bg-gray-100 focus:outline-none transition"
          onClick={handleCoverEditClick}
        >
         Edit your cover Photo <Camera className="ml-3" />
        </button>
      </div>
    </div>
  );
}

export default ProfilePicture;
