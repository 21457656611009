import React from "react";

export default function Video({ data }) {
  return (
    <div className="flex items-center w-full rounded-3xl bg-pink-50 h-full">
      <video controls className="w-full h-full object-contain">
        <source src={data} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}
