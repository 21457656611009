import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreRegisteration from "./pages/PreRegisteration";
import AboutUs from "./pages/AboutUs";
import Support from "./pages/Support";
import ContactUs from "./pages/ContactUs";
import Login from "./pages/Login";
import Register from "./pages/Register";
import MyLibrary from "./pages/ContributerPages/MyLibrary";
import UploadAssets from "./pages/ContributerPages/UploadAssets";
import Analytics from "./pages/ContributerPages/Analytics";
import AssetsDetail from "./pages/ContributerPages/AssetDetail";
import ProfileSection from "./pages/ContributerPages/ProfileSection";
import Library from "./pages/CreatorsPages/Library";
import Dashboard from "./pages/ContributerPages/Dashboard";
import TransationHistory from "./pages/ContributerPages/TransationHistory";
import ForgetPassword1 from "./pages/ForgetPassword1";
import ForgetPassword2 from "./pages/ForgetPassword2";
import ForgetPassword3 from "./pages/ForgetPassword3";
import ScrollToTop from "./components/common/ScrollToTop";
import AboutUsCont from "./components/ContributorsPage/AboutUsCont";
import SupportCont from "./components/ContributorsPage/SupportCont";
import ContactUsCont from "./components/ContributorsPage/ContactUsCont";
import Profile from "./pages/ContributerPages/Profile";
import ContributerAuth from "./utils/ContributerAuth";
import AuthRedirect from "./utils/AuthRedirect";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import ErrorPage from "./ErrorPage";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<PreRegisteration />} />
        {/* <Route exact path="/index" element={<Index />} />
        <Route exact path="/for-creators" element={<ForCreators />} />
        <Route exact path="/for-contributors" element={<Contributors />} /> */}
        <Route exact path="/about-us" element={<AboutUs />} />
        {/* <Route exact path="/features" element={<Features />} />
        <Route exact path="/pricing" element={<Pricing />} /> */}
        {/* <Route exact path="/how-it-works" element={<HowItWorks />} /> */}
        <Route exact path="/support" element={<Support />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        {/* <Route exact path="/resources" element={<Resources />} />
        <Route exact path="/joinus" element={<Joinus />} /> */}
        <Route path="/" element={<AuthRedirect />}>
          <Route exact path="login" element={<Login />} />
          <Route exact path="forgot-password" element={<ForgetPassword1 />} />
          <Route exact path="enter-otp" element={<ForgetPassword2 />} />
          <Route exact path="change-password" element={<ForgetPassword3 />} />
          <Route exact path="signup" element={<Register />} />
        </Route>

        {/* <Route exact path="/community" element={<Comunity />} />
        <Route exact path="/invest-in-aindera" element={<InvestinAindera />} /> */}
        <Route path="/contributor" element={<ContributerAuth />}>
          <Route exact path="dashboard" element={<Dashboard />} />
          <Route exact path="upload-asset" element={<UploadAssets />} />
          <Route exact path="my-library" element={<MyLibrary />} />
          <Route exact path="profile" element={<Profile />} />
          <Route exact path="asset/:id" element={<AssetsDetail />} />
          <Route
            exact
            path="transaction-history"
            element={<TransationHistory />}
          />
          <Route exact path="about-us" element={<AboutUsCont />} />
          <Route exact path="contact-us" element={<ContactUsCont />} />
          <Route exact path="support" element={<SupportCont />} />
          <Route exact path="analytics" element={<Analytics />} />
          <Route exact path="profile-section" element={<ProfileSection />} />
        </Route>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-conditions" element={<TermsAndConditions />} />
        {/* <Route
          exact
          path="/campaign-and-promotions"
          element={<CampaignPromotions />}
        /> */}
        <Route exact path="/creators/mylibrary" element={<Library />} />
        {/* <Route
          exact
          path="/creators/assetsdetails"
          element={<AssetsDetails />}
        />
        <Route
          exact
          path="/creators/transactionlist"
          element={<TransactionList />}
        /> */}
        {/* <Route
          exact
          path="/creators/mysubscription"
          element={<MySubscription />}
        />
        <Route exact path="/creators/profile" element={<Profile />} />
        <Route exact path="/creators/marketplace" element={<MarketPlace />} />
        <Route exact path="/creators/searchresult" element={<SearchResult />} /> */}
        {/* <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
        <Route exact path="/admin/content" element={<AdminContent />} />
        <Route exact path="/admin/support" element={<SupportDashboard />} />
        <Route
          exact
          path="/admin/usermanagement"
          element={<UserManagement />}
        />
        <Route exact path="/admin/userdetail" element={<UserDetail />} />
        <Route
          exact
          path="/admin/support-ticket-management"
          element={<SupportTicketManagement />}
        /> */}
        {/* <Route
          exact
          path="/community/homepage1"
          element={<CommunityHomePage />}
        />
        <Route
          exact
          path="/community/homepage2"
          element={<CommunityHomePage2 />}
        />
        <Route exact path="/community/forum1" element={<Forum1 />} />
        <Route exact path="/community/forum2" element={<Forum2 />} />
        <Route exact path="/community/message" element={<Message />} />
        <Route
          exact
          path="/investor/investment-opportunity"
          element={<InvestmentOpportunities />}
        />
        <Route exact path="/investor/kyc" element={<KYC />} />
        <Route
          exact
          path="/marketing/campaign-management"
          element={<CampaignManagement />}
        /> */}
        {/* <Route
          exact
          path="/marketing/dashboard"
          element={<MarketingDashbaord />}
        />
        <Route
          exact
          path="/marketing/partner-dashboard"
          element={<PartnerDashboard />}
        /> */}
        <Route exact path="*" element={<ErrorPage />} /> 
      </Routes>
    </Router>
  );
}

export default App;
