import { post } from "./apiRequests";

//Post Newsletter
export async function newsLetter(credentials) {
  try {
    const response = await post("newsLetter/subscribe", credentials);
    return response;
  } catch (error) {}
}
//Contactus Form
export async function contactUs(credentials) {
  try {
    const response = await post("contactus/create", credentials);
    return response;
  } catch (error) {}
}
//Support Form
export async function supportForm(credentials) {
  try {
    const response = await post("support/create", credentials);
    return response;
  } catch (error) {}
}
