import React from "react";
import { Check, Heart } from "lucide-react";

function Card() {
  return (
    <div className="max-w-sm mx-auto bg-white rounded-xl shadow-lg overflow-hidden ">
      <div className="relative">
        <img
          src="../assets/images/product.png"
          alt="Product"
          className="w-full h-48 object-cover p-4 rounded-[30px]"
        />
        <button className="absolute top-7 right-7 bg-white rounded-full p-1 shadow hover:bg-gray-200 transition">
          <Heart size={18} fill="#CA63CE" className="text-primary" />
        </button>
      </div>
      <div className="p-4">
        <div className="flex justify-between">
          <div className="text font-semibold mb-2">Product Title</div>
          <div className="text font-semibold mb-2 text-primary">$125</div>
        </div>
        <p className="text-gray-700 text-sm mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non
          urna nec odio malesuada tincidunt.
        </p>
        <div className="flex items-center mb-3 text-sm">Smart Contarct<Check size={12} className="text-secondary ms-2"/> </div> 
        <div className="flex justify-between items-center">
          <button className="bg-primary text-white px-3 py-1 rounded-3xl hover:bg-black transition">
            Purchase
          </button>
          <button className="border border-primary text-primary px-5 py-1 rounded-3xl transition">
            Rent
          </button>
        </div>
      </div>
    </div>
  );
}

export default Card;
