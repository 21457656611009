import React from "react";
import { User } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

function NavbarAuth() {
  const location = useLocation();
  const isOnLoginOrJoinUsPage =
    location.pathname === "/login" || location.pathname === "/joinus";
  const isOnSignUpPage = location.pathname === "/signup";

  return (
    <nav className="">
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-24">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/">
              <img src="/assets/images/logo.svg" alt="Logo" width={"70"} />
            </Link>
          </div>

          <div className="flex items-center">
            {isOnLoginOrJoinUsPage ? (
              <Link to="/signup">
                <button className="flex items-center text-black md:text-lg hover:text-gray-900">
                  <User className="w-5 h-5 mr-1" color="#6EB387" /> Sign Up
                </button>
              </Link>
            ) : isOnSignUpPage ? (
              <Link to="/login">
                <button className="flex items-center text-black md:text-lg hover:text-gray-900">
                  <User className="w-5 h-5 mr-1" color="#6EB387" /> Log in
                </button>
              </Link>
            ) : (
              <>
                <Link to="/joinus">
                  <button className="flex items-center text-black md:text-lg hover:text-gray-900">
                    <User className="w-5 h-5 mr-1" color="#6EB387" /> Join Us
                  </button>
                </Link>
                <Link to="/login" className="ml-4">
                  <button className="flex items-center text-black md:text-lg hover:text-gray-900">
                    <User className="w-5 h-5 mr-1" color="#6EB387" /> Log in
                  </button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavbarAuth;
