
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function UsageGraph() {
  const data = {
    labels: ["1 Jan", "2 Jan", "3 Jan", "4 Jan", "5 Jan", "6 Jan", "7 Jan"],
    datasets: [
      {
        label: "Earnings in $ (Category 1)",
        data: [
          [600, 1600],
          [700, 1800],
          [500, 1400],
          [1200, 2500],
          [1000, 2200],
          [1300, 2700],
          [800, 2000],
        ],
        backgroundColor: "#6EB387",
        borderWidth: 0,
        borderRadius: 40,
        barThickness: 10,
        borderSkipped: false,
      },
      {
        label: "Earnings in $ (Category 2)",
        data: [
          [500, 1500],
          [600, 1700],
          [700, 1600],
          [1400, 2300],
          [1100, 2100],
          [1600, 2600],
          [900, 1900],
        ],
        backgroundColor: "#CA63CE",
        borderWidth: 0,
        borderRadius: 40,
        barThickness: 10,
        borderSkipped: false,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        display: true,
      },
      title: {
        display: true,
        text: "Category Earnings Over 7 Days",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Dollar ($)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Date (Jan)",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div>
      <div className="font-bold text-lg mt-8 mb-12">Peak Usage Time</div>

      <div className="shadow-lg rounded-xl p-4">
        <div className="flex justify-around my-5">
          <div>Total Views</div>
          <div className="text-3xl">
            {" "}
            12K{" "}
            <span className="text-sm bg-pink-100 px-1 rounded text-pink-500">
              +10%
            </span>
          </div>
        </div>
        <div className="w-full  mx-auto">
          <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default UsageGraph;
