import React, { useState } from "react";
import CoverPhoto from "./CoverPhoto";
import { useDispatch, useSelector } from "react-redux";
import AccountSetting from "./AccountSetting";
import Messages from "./Messages";
import SocialLinks from "./SocialLinks";
import ProfileImage from "./ProfileImage";
import toast from "react-hot-toast";
import { getUserData, updateUserProfile } from "../../../api/authApi";
import { setUser } from "../../../redux/actions/userActions";
import ChangePassword from "./ChangePassword";

function Main() {
  const [selectedTab, setSelectedTab] = useState("Account Setting");
  const tabs = ["Account Setting", "Change Password", "" , ""];
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.userData.user);
  const token = useSelector((state) => state.userData.token);
  const [data, setData] = useState({
    city: userData?.city || "",
    country: userData?.country || "",
    coverPhoto:
      userData?.coverPhoto || "/assets/images/CreatorsPages/profile/cover.png",
    description: userData?.description || "",
    email: userData?.email || "",
    fullName: userData?.fullName || "",
    phoneNumber: userData?.phoneNumber || "",
    postCode: userData?.postCode || "",
    profileLink: userData?.profileLink || "",
    profilePhoto: userData?.profilePhoto || "/assets/images/homepage/image.png",
    role: userData?.role || "",
    pinterest: userData?.pinterest || "https://www.pinterest.com/",
    facebook: userData?.facebook || "https://www.facebook.com/",
    instagram: userData?.instagram || "https://www.instagram.com/",
    walletAddress: userData?.walletAddress || "",
  });
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: inputType === "checkbox" ? checked : value,
    }));
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await updateUserProfile(data, token);
      if (result && result.status === "success") {
        toast.success(result.message || "User Info Updated");
        const updatedData = await getUserData(token);
        dispatch(setUser(updatedData.data));
      } else {
        toast.error(result.message || "Failed to Update.");
      }
    } catch (error) {
      toast.error("An error occurred during Updation.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <CoverPhoto data={data} setData={setData} />
      <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
        <div className="grid lg:grid-cols-12 grid-cols-1 gap-10">
          <div className="lg:col-span-4">
            <div className="border border-[#ebc1ec] rounded-3xl md:p-5 p-5 ">
              <ProfileImage data={data} setData={setData} />
              <div className="mt-4">
                <h2 className="md:text-3xl font-bold mb-1 text-center">
                  {userData?.fullName}
                </h2>
                <p className="text-center text-balck text-opacity-70">
                  {userData?.role}
                </p>
              </div>
              <div className="mt-4 w-full">
                <button className="w-full font-bold  bg-primary text-white py-3 rounded-3xl hover:bg-black">
                  View Profile
                </button>
              </div>
              <SocialLinks userData={data} handleChange={handleChange} />
            </div>
          </div>
          <div className="lg:col-span-8">
            <div className="border border-[#ebc1ec] rounded-3xl md:p-8 p-5">
              <div className="flex items-center justify-between mb-10">
                {tabs.map((tab) => (
                  <h2
                    key={tab}
                    onClick={() => setSelectedTab(tab)}
                    className={`md:text-md font-bold cursor-pointer ${
                      selectedTab === tab
                        ? "text-primary border-primary border-b-2 pb-2"
                        : "text-black"
                    } hover:text-primary hover:border-b-2 pb-2 border-primary`}
                  >
                    {tab}
                  </h2>
                ))}
              </div>
              <div className="border-t border-primary">
                {selectedTab === "Account Setting" && (
                  <AccountSetting
                    userData={data}
                    handleChange={handleChange}
                    handleUpdate={handleUpdate}
                    loading={loading}
                  />
                )}
                {selectedTab === "Change Password" && <ChangePassword />}
                {selectedTab === "Messages" && <Messages />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Main;
