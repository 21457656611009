import React from "react";
import ButtonLoader from "../../common/ButtonLoader";

function DeleteConfirmationModal({ isOpen, onClose, onConfirm, loading }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-6 w-[400px]">
        <h2 className="text-lg font-semibold mb-4">Delete Confirmation</h2>
        <p>Are you sure you want to delete this asset?</p>
        <div className="flex justify-end gap-4 mt-4">
          <button
            className="bg-gray-200 px-4 py-2 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-primary text-white px-4 py-2 rounded"
            onClick={onConfirm}
            disabled={loading}
          >
            {loading ? <ButtonLoader/> : "Yes, Sure"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmationModal;
