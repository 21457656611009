import React from "react";
import { MailIcon, MappinIcon, PhoneIcon } from "../../utils/Icons";
import { Facebook, Instagram, Linkedin, Twitter } from "lucide-react";

export default function ContactInfo() {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="mt-7 border-b-2 pb-5 md:pb-8 border-[#6EB387]">
        <h2 className="text-2xl font-bold mt-12 md:mt-0 mb-6">
          Contact Information:
        </h2>
        <div className="space-y-6">
          <div className="flex items-center gap-10">
            <MappinIcon size={30} />
            <h2 className="text-lg text-black">
              <span className="font-bold">AiNDREA Inc.</span> , 123 Creative
              Lane
              <br />
              Suite 456, Innovation City, IN 78910
            </h2>
          </div>
          <div className="flex items-center gap-10">
            <MailIcon size={30} />
            <h2 className="text-lg text-black">support@aindrea.com</h2>
          </div>
          <div className="flex items-center gap-10">
            <PhoneIcon size={30} />
            <h2 className="text-lg text-black ">+1 (234) 567-8901</h2>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="flex justify-center space-x-8">
          <Facebook className="w-10 h-10 text-primary hover:text-black" />
          <Twitter className="w-10 h-10 text-primary hover:text-black" />
          <Instagram className="w-10 h-10 text-primary hover:text-black" />
          <Linkedin className="w-10 h-10 text-primary hover:text-black" />
        </div>
        <h2 className="text-black text-lg font-bold mt-3 text-center">
          Follow us for updates and news!
        </h2>
      </div>
    </div>
  );
}
