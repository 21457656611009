import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthRedirect = () => {
    const isAuthenticated = useSelector((state) => state.userData.token);

  return isAuthenticated ? <Navigate to="/contributor/dashboard" /> : <Outlet />;
};

export default AuthRedirect;
