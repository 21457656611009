import React from "react";

function Partners() {
  const logos = [
    { name: "Forbes", src: "/assets/images/partners/forbes.svg" },
    { name: "Netflix", src: "/assets/images/partners/netflix.svg" },
    { name: "Amazon", src: "/assets/images/partners/amazon.svg" },
    {
      name: "Search Engine Journal",
      src: "/assets/images/partners/sej.svg",
    },
    {
      name: "Entrepreneur Magazine",
      src: "/assets/images/partners/entrepreneur.svg",
    },
  ];

  return (
    <div>
      <h3 className="text-center text-3xl font-bold mt-20">
        The founding team of{" "}
        <span className="text-primary">AiNDREA has worked</span> with global
        brands such as . . .
      </h3>
      <div className="w-full mt-7 bg-[#E671E51A] overflow-hidden py-4">
        <div className="relative flex items-center justify-center">
          <div className="animate-marquee flex">
            {[...logos, ...logos].map((logo, index) => (
              <div key={index} className="mx-8 w-40 my-auto flex-shrink-0">
                <img
                  src={logo.src}
                  alt={logo.name}
                  width={160}
                  height={50}
                  className="object-contain"
                />
              </div>
            ))}
          </div>
          <div className="animate-marquee2 flex absolute top-0 items-center justify-center">
            {[...logos, ...logos].map((logo, index) => (
              <div key={index} className="mx-8 w-40 my-auto flex-shrink-0">
                <img
                  src={logo.src}
                  alt={logo.name}
                  width={160}
                  height={50}
                  className="object-contain"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
