import React from "react";

export default function ThreedModel({ data, alt = "Preview" }) {
  return (
    <div className="flex items-center w-full rounded-3xl bg-pink-50 h-full">
    <model-viewer
      alt={alt}
      src={data}
      ar
      shadow-intensity="1"
      camera-controls
      touch-action="pan-y"
      class="h-full w-full"
    ></model-viewer>
    </div>
  );
}
