import React, { useState } from "react";
import { CameraIcon } from "../../../utils/Icons";
import { singleFileUpload } from "../../../api/FileUpload";
import toast from "react-hot-toast";
import { setUser } from "../../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, updateUserProfile } from "../../../api/authApi";
import ButtonLoader from "../../common/ButtonLoader";
import ImageCrop from "./ImageCrop";

function CoverPhoto({ data, setData }) {
  const [loading, setLoading] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const token = useSelector((state) => state.userData.token);
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsCropping(true);
    }
  };

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = new Image();
    image.src = URL.createObjectURL(imageSrc);
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    if (!ctx) throw new Error("Failed to create 2D context");

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  };

  const handleCropConfirm = async (cropPixels) => {
    if (!selectedFile || !cropPixels) return;
    setIsCropping(false);
    setLoading(true);

    try {
      const croppedImage = await getCroppedImg(selectedFile, cropPixels);
      if (!croppedImage) {
        toast.error("Failed to create cropped image.");
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("file", croppedImage, selectedFile.name);

      const result = await singleFileUpload(formData);
      if (result && result.data) {
        const updatedData = { ...data, coverPhoto: result.data };
        await updateUserProfile(updatedData, token);

        const userData = await getUserData(token);
        dispatch(setUser(userData.data));
        setData(updatedData);
        toast.success("Cover photo updated successfully!");
      } else {
        toast.error("Failed to update cover photo.");
      }
    } catch (error) {
      console.error("Error in handleCropConfirm:", error);
      toast.error("An error occurred while updating the cover photo.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      <img
        src={
          data.coverPhoto || "/assets/images/CreatorsPages/profile/cover.png"
        }
        alt="Cover"
        className="w-full md:h-80 h-52 object-cover"
      />
      <input
        type="file"
        id="coverFileInput"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <div className="absolute md:bottom-60 bottom-36 right-4">
        <button
          className="flex items-center gap-3 bg-white rounded-full py-2 px-5 shadow-md hover:bg-gray-100 transition"
          onClick={() => document.getElementById("coverFileInput").click()}
          disabled={loading}
        >
          {loading ? <ButtonLoader /> : <CameraIcon color="black" />} Edit Cover
          Photo
        </button>
      </div>
      {isCropping && (
        <ImageCrop
          selectedFile={selectedFile}
          aspect={3 / 1}
          onConfirm={handleCropConfirm}
          onClose={() => setIsCropping(false)}
        />
      )}
    </div>
  );
}

export default CoverPhoto;
