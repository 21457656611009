import React, { useState } from "react";
import { contactUs } from "../../api/UserContact";
import toast from "react-hot-toast";
import ButtonLoader from "../common/ButtonLoader";

function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContactUs = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await contactUs({ ...credentials });
      if (result && result.status === "success") {
        setCredentials({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
        toast.success(result.message || "Message successfully submitted!");
        
      } else {
        toast.error(result.message || "Failed to send the message.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="md:ps-32">
      <div className="my-auto">
        <form onSubmit={handleContactUs}>
          <div className="grid grid-cols-1 gap-6 ">
            <div className="space-y-2">
              <label htmlFor="name" className="font-medium text-lg text-black ">
                Name
              </label>
              <input
                id="name"
                name="name"
                value={credentials.name}
                onChange={handleChange}
                placeholder="Enter your full name"
                className="p-3 rounded w-full outline-primary text-black"
                style={{ backgroundColor: "#F3F6F9" }}
                required
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="email" className="font-medium text-lg text-black ">
                Email Address
              </label>
              <input
                id="email"
                name="email"
                value={credentials.email}
                onChange={handleChange}
                placeholder="Enter your email address"
                className="p-3 rounded w-full outline-primary text-black"
                style={{ backgroundColor: "#F3F6F9" }}
                required
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="subject" className="font-medium text-lg text-black ">
                Subject
              </label>
              <input
                id="subject"
                name="subject"
                value={credentials.subject}
                onChange={handleChange}
                placeholder="Enter the subject of your message"
                className="p-3 rounded w-full outline-primary text-black"
                style={{ backgroundColor: "#F3F6F9" }}
                required
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="message" className="font-medium text-lg text-black ">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={credentials.message}
                onChange={handleChange}
                placeholder="Write your message here"
                rows="3"
                className="p-3 rounded w-full outline-primary text-black"
                style={{ backgroundColor: "#F3F6F9" }}
                required
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={loading}
              className="flex items-center justify-center mt-7 w-full bg-primary text-white py-3 rounded-3xl hover:bg-black"
            >
              {loading ? <ButtonLoader /> : "Send Message"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
