import { Facebook, Instagram, Twitter, Linkedin } from "lucide-react";
import React from "react";

function Bio() {
  return (
    <div className="mt-20 max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="grid grid-cols-12 gap-4">
        <div className="md:col-span-9 col-span-12">
          <div className="text-2xl font-bold">John Doe</div>
          <div className="text-gray-700 my-5">
            Contributor Since November 04, 2015
          </div>
          <div className="text-gray-700">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
            dolor sit amet.
          </div>
        </div>

        <div className="md:col-span-3 col-span-12">
          <div className="text-2xl font-bold mb-2 text-center">
            Visit Social Media
          </div>
          <div className="flex justify-around mt-7">
            <Facebook  size={36} className="text-pink-400" />
            <Twitter  size={36} className="text-pink-400" />
            <Instagram  size={36} className="text-pink-400" />
            <Linkedin  size={36} className="text-pink-400" />
          </div>
          <button className="bg-primary hover:bg-black w-full mt-7 text-white p-3 rounded-3xl">
            Message
          </button>
        </div>
      </div>
    </div>
  );
}

export default Bio;
