import { post } from "./apiRequests";


//Single File Upload
export async function singleFileUpload(formData, ) {
 try {
  const response = await post("upload/single", formData, "", "formData");
  return response;
 } catch (error) {
  throw error;
 }
}

//Multi File Upload
export async function multipleFileUpload(formData) {
 try {
  const response = await post("upload/multiple", formData, "", "formData");
  return response;
 } catch (error) {
  throw error;
 }
}

   