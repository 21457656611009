import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgetPassword } from "../../api/authApi";
import toast from "react-hot-toast";
import ButtonLoader from "../common/ButtonLoader";

function Form() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const handleChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: inputType === "checkbox" ? checked : value,
    }));
  };
  const handleForget = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await forgetPassword({ ...credentials });
      if (result && result.status === "success") {
        toast.success(result.message || "OTP Successfully Sent To Your Email!");
        const storedData = {
          email: credentials.email,
          token: result.data,
          otp: result.otp,
          status: false
        };
        localStorage.setItem("forgetPasswordData", JSON.stringify(storedData));
        navigate("/enter-otp");
      } else {
        toast.error(result.message || "Failed to Reset Password.");
      }
    } catch (error) {
      toast.error("An error occurred during Reset Password.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="flex flex-col md:flex-row items-center gap-8">
        <div className="w-full md:w-1/2 order-2">
          <div className="my-auto">
            <h2 className="text-2xl md:text-5xl text-gray-800 font-semibold mb-4">
              Forgot Password
            </h2>
            <form onSubmit={handleForget}>
              <div className="grid grid-cols-1 gap-4 my-6">
                <div className="space-y-3">
                  <label
                    for=""
                    className="font-medium text-lg  text-black text-opacity-70 mb-0"
                  >
                    Email Address
                  </label>
                  <input
                    id="email"
                    type="email"
                    placeholder="Enter the Email Address associated with account"
                    className="bg-[#F6FAF6] p-3 rounded-xl w-full border-none outline-none"
                    required
                    value={credentials.email}
                    name="email"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="space-y-3">
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full bg-secondary hover:bg-black transition duration-300 ease-in-out text-white py-2 rounded-full"
                >
                  {loading ? <ButtonLoader /> : "Get OTP"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full md:w-1/2 order-1">
          <div className="w-full flex items-center justify-center">
            <img
              src="/assets/images/forcreators/getstarted.png"
              alt="Get Started"
              className="rounded-3xl object-cover md:w-[90%] w-[100%]"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Form;
