import React from "react";

function ContributerBreadCrumb(props) {
  return (
    <div className="relative">
      <div>
        <img
          src={props?.img}
          alt="Background"
          className="w-full h-48 object-cover"
        />
      </div>
      <div className="absolute inset-0 flex flex-col items-start max-w-8xl mx-auto  px-4 sm:px-6 lg:px-24 justify-center text-white">
        <div className="font-bold text-2xl md:text-4xl ">{props?.title}</div>
        <div className="text-sm sm:text-base md:text-lg lg:text-xl mt-2">
          {props?.subtitle}
        </div>
      </div>
    </div>
  );
}

export default ContributerBreadCrumb;
