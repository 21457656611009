import React from "react";
import CreatorsNavbar from "../../components/common/CreatorsNavbar";
import CreatorsBreadCrumb from "../../components/common/CreatorsBreadCrumb";
import LibraryCard from "../../components/ForCreatorsPages/MyLibrary/LibraryCard";
import Footer from "../../components/common/Footer";

function Library() {
  return (
    <>
      <CreatorsNavbar />
      <CreatorsBreadCrumb
        img={"/assets/images/CreatorsPages/MyLibrary/breadcrumb.png"}
        title={"My Library"}
        subtitle={"Lorem ipsum dolor sit amet, consectetur adipiscing"}
      />
      <LibraryCard/>
      <Footer />
    </>
  );
}

export default Library;
