import { Pencil, Trash } from "lucide-react";
import React, { useEffect, useState } from "react";
import {
  deleteUserAsset,
  getAssetsbyId,
  updateAssetsbyId,
} from "../../../api/Assets";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import ButtonLoader from "../../common/ButtonLoader";
import DeleteConfirmationModal from "../MyLibrary/DeleteConfirmationModal";
import { useNavigate } from "react-router-dom";
import Music from "../Previews/Music";
import Video from "../Previews/Video";
import ThreedModel from "../Previews/3dModel";
import Image from "../Previews/Image";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function EditProduct({ id, setTitle, setCategory }) {
  const [isEditable, setIsEditable] = useState(false);
  const [assets, setAssets] = useState({});
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const token = useSelector((state) => state.userData.token);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAsset = async () => {
      try {
        const res = await getAssetsbyId(id, token);

        if (res.status === "success") {
          setAssets(res.asset);
          setTitle(res.asset.title);
          setCategory(res.asset.category);
        } else {
          toast.error("Failed to fetch Data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching data.");
      } finally {
      }
    };
    fetchAsset();
    // eslint-disable-next-line
  }, [id, token]);

  const updateAsset = async () => {
    setLoading(true);
    try {
      const res = await updateAssetsbyId(id, assets, token);

      if (res.status === "success") {
        setIsEditable(false);
        setAssets(res.asset);
        toast.success("Asset updated successfully.");
      } else {
        toast.error("Failed to Update Data.");
      }
    } catch (error) {
      toast.error("An error occurred while Updating data.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setDelLoading(true);
    try {
      const result = await deleteUserAsset(id, token);
      if (result && result.status === "success") {
        toast.success(result.message || "Deleted Successfully!");
        navigate("/contributor/my-library");
      } else {
        toast.error(result.message || "Failed to Delete.");
      }
    } catch (error) {
      toast.error("An error occurred during Delete.");
    } finally {
      setDelLoading(false);
      setShowDeleteModal(false);
    }
  };

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleCancelClick = () => {
    setIsEditable(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssets({ ...assets, [name]: value });
  };

  return (
    <div>
      <div className="grid md:grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-5 flex items-center mt-3">
          <div className="flex items-center justify-center w-full h-[500px]">
            {(() => {
              switch (assets?.category) {
                case "Images":
                  return <Image data={assets?.file} alt={assets?.title} />;
                case "3D Models":
                  return (
                    <ThreedModel data={assets?.file} alt={assets?.title} />
                  );
                case "Videos":
                  return <Video data={assets?.file} />;
                case "Music":
                  return <Music data={assets?.file} />;
                default:
                  return <Skeleton height={384} className="rounded-3xl" />;
              }
            })()}
          </div>
        </div>
        <div className="col-span-12 md:col-span-7">
          <div className="flex justify-end my-1">
            {!isEditable ? (
              <div className="flex gap-3">
                <button
                  onClick={handleEditClick}
                  className="bg-white shadow-md p-2 rounded-full w-9 h-9 flex items-center justify-center"
                >
                  <Pencil className="text-primary" size={24} />
                </button>
                <button
                  onClick={() => setShowDeleteModal(true)}
                  className="bg-white shadow-md p-2 rounded-full w-9 h-9 flex items-center justify-center"
                >
                  {delLoading ? (
                    <ButtonLoader />
                  ) : (
                    <Trash className="text-red-600" size={24} />
                  )}
                </button>
              </div>
            ) : (
              <div className="flex gap-3">
                <button
                  onClick={updateAsset}
                  className="bg-primary text-white px-4 py-2 rounded-3xl hover:bg-black flex items-center justify-center"
                  disabled={loading}
                >
                  {loading ? <ButtonLoader /> : "Save Changes"}
                </button>
                <button
                  onClick={handleCancelClick}
                  className="bg-white border-2 border-secondary text-black px-4 py-2 rounded-3xl"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div className="ml-0 lg:ml-10 grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-6">
              <label htmlFor="title" className="font-bold">
                Assets Title
              </label>
              <input
                id="title"
                type="text"
                placeholder="Enter the title of your asset"
                className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none"
                value={assets?.title}
                name="title"
                required
                disabled={!isEditable}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <label htmlFor="license" className="font-bold">
                License Type
              </label>
              <select
                id="license"
                className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none"
                disabled
                value={assets?.licenceType}
                name="licenceType"
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select License Type
                </option>
                <option value="Royalty-Free">Royalty-Free</option>
                <option value="Exclusive">Exclusive</option>
                <option value="Non-Exclusive">Non-Exclusive</option>
              </select>
            </div>
            <div className="col-span-12 md:col-span-6">
              <label htmlFor="description" className="font-bold">
                Description
              </label>
              <textarea
                id="description"
                placeholder="Provide a detailed description of your asset"
                className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none"
                required
                rows={4}
                value={assets?.description}
                disabled={!isEditable}
                name="description"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <label htmlFor="price" className="font-bold">
                Price ($)
              </label>
              <input
                id="price"
                type="number"
                placeholder="Set your price for this asset"
                className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none"
                required
                value={assets?.price}
                disabled={!isEditable}
                name="price"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <label htmlFor="tags" className="font-bold">
                Tags
              </label>
              <input
                id="tags"
                type="text"
                placeholder="e.g., nature, abstract, music"
                className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none"
                required
                value={assets?.tags}
                name="tags"
                disabled={!isEditable}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <label htmlFor="category" className="font-bold">
                Category
              </label>
              <select
                id="category"
                className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none"
                disabled
                name="category"
                value={assets?.category}
                onChange={handleInputChange}
              >
                <option disabled value="">
                  Select Category
                </option>
                <option value="Images">Images</option>
                <option value="Videos">Videos</option>
                <option value="Music">Music</option>
                <option value="3D Models">3D Models</option>
                <option value="AI Avatars">AI Avatars</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="col-span-12">
              <h3 className="text-lg font-bold">NFT Details: </h3>
            </div>
            <div className="col-span-12 md:col-span-4">
              <label htmlFor="tokenid" className="font-bold">
                Token ID
              </label>
              <input
                id="tokenid"
                type="text"
                placeholder="Token ID"
                className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none"
                required
                value={assets?.tokenId}
                disabled={true} // Always disabled
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <label htmlFor="blockchain" className="font-bold">
                Blockchain
              </label>
              <input
                id="blockchain"
                type="text"
                placeholder="Etherium"
                className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none"
                required
                value={assets?.chain}
                disabled={true}
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <label htmlFor="owner" className="font-bold">
                Owner
              </label>
              <input
                id="owner"
                type="text"
                placeholder="Contributor Name"
                className="bg-pink-50 p-3 rounded-xl w-full border-none outline-none"
                required
                value={assets?.owner}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Analytics/> */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
          loading={delLoading}
        />
      )}
    </div>
  );
}

export default EditProduct;
