import React from "react";
import { Helmet } from "react-helmet";

function MetaTags(props) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta
        name="keywords"
        content={props.keywords ? props.keywords : "Aindrea"}
      />
      <link rel="canonical" href={process.env.REACT_APP_IMG_URL} />
      <meta property="og:url" content={process.env.REACT_APP_IMG_URL} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={props.type ? props.type : "website"} />
      <meta property="og:site_name" content="Brandsonify" />
      <meta name="author" content="Aindrea Team" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={process.env.REACT_APP_IMG_URL} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      <meta
        property="twitter:image"
        content={process.env.REACT_APP_IMG_URL}
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={props.title} />
      <meta property="twitter:description" content={props.description} />
    </Helmet>
  );
}

export default MetaTags;
