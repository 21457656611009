import { X } from "lucide-react";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";

function PreRegisterSuccessModal({ setIsOpen }) {
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        document.body.style.overflow = "hidden";

        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            document.body.style.overflow = "auto";
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <div className="fixed inset-0 z-50 md:overflow-y-auto flex items-center justify-center">
                {/* Modal background overlay */}
                <div className="fixed inset-0 bg-black opacity-50"></div>

                {/* Modal content */}
                <div className="relative bg-white w-full max-w-2xl md:py-8 md:h-auto h-full rounded-lg shadow-lg z-50 md:overflow-y-auto">
                    <img
                        src="/assets/images/preregister-bg.png"
                        alt="BG"
                        className="absolute bottom-0 md:max-w-[40%] max-w-[100%] -z-10"
                    />
                    <div className="h-16 flex justify-between md:mx-8 mx-3">
                        <img src="/assets/images/logo.svg" alt="Logo" width={70} />
                        <button
                            onClick={() => setIsOpen(false)}
                            className="p-2 text-gray-500 hover:text-gray-700"
                        >
                            <X className="h-6 w-6" />
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="md:mx-8 mx-3 p-5 flex items-center justify-center h-full">
                        <div className="space-y-6 text-center md:max-w-lg">
                            <h2 className="text-3xl md:text-4xl font-bold">
                                Thank You for <br />
                                <span className="text-primary">Pre-registering!</span>
                            </h2>
                            <p className="text-gray-600">
                                Your request to join AiNDREA has been received. We will notify you
                                once the platform is fully launched.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Confetti */}
            <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                style={{zIndex: 99999999,}}
            />
        </>
    );
}

export default PreRegisterSuccessModal;
