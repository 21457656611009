import { ArrowRight } from "lucide-react";
import React, { useEffect, useState } from "react";

function CustomCursor() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hovering, setHovering] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isLargeScreen) return; // Exit if screen size is smaller than 768px

    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseEnter = () => setHovering(true);
    const handleMouseLeave = () => setHovering(false);

    // Track mouse movement
    window.addEventListener("mousemove", handleMouseMove);

    // Track hover on buttons and anchor tags
    const buttons = document.querySelectorAll("button, a");
    buttons.forEach((btn) => {
      btn.addEventListener("mouseenter", handleMouseEnter);
      btn.addEventListener("mouseleave", handleMouseLeave);
    });

    // Cleanup
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      buttons.forEach((btn) => {
        btn.removeEventListener("mouseenter", handleMouseEnter);
        btn.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, [isLargeScreen]);

  if (!isLargeScreen) return null; // Don't render the custom cursor on small screens

  return (
    <>
      <div
        className={`fixed pointer-events-none transition-all duration-200 ease-out ${
          hovering ? "w-16 h-16 opacity-80" : "w-8 h-8 opacity-50"
        } bg-primary rounded-full flex items-center justify-center`}
        style={{
          left: `${position.x - (hovering ? 32 : 16)}px`,
          top: `${position.y - (hovering ? 32 : 16)}px`,
          zIndex: 9999,
        }}
      >
        {hovering && (
          <span className="text-black text-lg">
            <ArrowRight size={28} />
          </span>
        )}
      </div>
    </>
  );
}

export default CustomCursor;
