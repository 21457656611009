import { post, get, put } from "./apiRequests";

//Register User
export async function userRegister(credentials) {
  try {
    const response = await post("auth/register", credentials);
    return response;
  } catch (error) {}
}
//Login User
export async function userLogin(credentials) {
  try {
    const response = await post("auth/login", credentials);
    return response;
  } catch (error) {}
}
//Get User Details
export async function getUserData(userToken) {
  try {
    const response = await get("user/getUserDetails", userToken);
    return response;
  } catch (error) {}
}
//Forget Password
export async function forgetPassword(credentials) {
  try {
    const response = await post("auth/forgotPassword", credentials);
    return response;
  } catch (error) {}
}

//Update Password
export async function updatePassword(credentials , userToken) {
  try {
    const response = await post("auth/changePassword", credentials , userToken);
    return response;
  } catch (error) {}
}

//Update User Profile
export async function updateUserProfile(credentials, userToken) {
  try {
    const response = await put("user/update/profile", credentials, userToken);
    return response;
  } catch (error) {}
}

//Update User Password
export async function updateUserPassword(credentials, userToken) {
  try {
    const response = await post("auth/updatePassword", credentials, userToken);
    return response;
  } catch (error) {}
}