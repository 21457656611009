import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowRightAbove } from '../../utils/Icons'

function WhyJoin() {
  return (
    <div id='whyjoin' className='px-5 md:px-24 mx-auto my-20 text-center md:text-start w-full '>
      <div className='text-center '>
        <h1 className="text-4xl md:text-5xl font-bold leading-normal md:leading-loose">
          Why Join <span className='text-[#CA63CE]'>Aindrea?</span>
        </h1>
        <p className='text-1xl md:text-2xl leading-loose'>Unlock the Power of Digital Content Creation & Commerce</p>
      </div>
      <div className='grid grid-cols-12 gap-4 mt-10'>
        <div className="col-span-12 md:col-span-8  flex border border-[#CA63CE] rounded-2xl items-center ">
          <div className='grid grid-cols-12 gap-4 p-4'>
            <div className="col-span-12 md:col-span-6 flex flex-col justify-start md:items-start">
              <h1 className='text-3xl mb-4 font-bold md:mt-2'>Protect Your Creation with <br /><span className='text-[#CA63CE]'>Blockchain Technology</span></h1>
              <p className='md:me-10 mt-5 text-md'>
                Your content is your livelihood, and we take that seriously. Our platform utilizes blockchain technology built on Web3 to ensure your digital assets are fully protected, secure, and transparent. Every sale and transaction is recorded immutably, so you never have to worry about unauthorized use or copyright issues again.
              </p>
              <button className="w-full md:w-auto mt-4 md:mt-auto px-8 py-3 rounded-full lg:text-xl text-primary  border-2 border-primary hover:border-black  hover:bg-black hover:text-white transition duration-300 ease-in-out">
                Explore
              </button>
            </div>
            <div className='col-span-12 md:col-span-6 md:px-10'>
              <img className='w-full ' src="assets/images/union.png" alt="union" />
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 rounded-2xl bg-[#6EB387] relative py-5 px-8 md:py-5 overflow-hidden flex flex-col">
          <img className='absolute top-0 left-0 w-[60%]' src="assets/images/bt-hand.png" alt="bitcoin" />
          <div className='bg-white mt-24 md:mt-28 rounded-2xl p-4'>
            <h2 className='text-2xl mb-4 font-bold'><span className='text-[#6EB387]'>Earn 90%</span> of Every Sale Keep What <span className='text-[#6EB387]'>You Deserve</span></h2>
          </div>
          <p className='md:me-10 mt-5 text-white text-md'>
            Expand your reach like never before. Join a thriving global community where buyers and creators come together to collaborate, inspire, and trade. Whether you’re looking to sell your work or connect with other like-minded creatives, our platform opens doors to new opportunities and a worldwide audience.            </p>
          <Link className='flex items-center mt-5 gap-5 ms-auto underline text-white'>Explore More <ArrowRightAbove color='#6EB387' /></Link>
        </div>

      </div>
      <div className='grid grid-cols-12 gap-4 mt-10'>
        <div className="col-span-12 md:col-span-8 md:order-2 flex border border-[#6EB387] rounded-2xl items-center">
          <div className='grid grid-cols-12 gap-4 p-4'>
            <div className="col-span-12 md:col-span-6 flex flex-col justify-start md:items-start">
              <h1 className='text-3xl mb-4 font-bold md:mt-2'><span className='text-[#6EB387]'>Enhance</span> Your Content with <span className='text-[#6EB387]'>AI-Powered Tools</span></h1>
              <p className='md:me-10 mt-2 text-md'>
                Let your work be found by the right audience. Our advanced AI tools automatically generate precise descriptions and tags for your content, ensuring that your photos, videos, and designs appear in relevant searches. By optimizing your assets with AI-driven keywords, you’ll increase visibility and get discovered faster by buyers looking for exactly what you offer. Spend less time on tedious details and more time creating.              </p>
              <button className="w-full md:w-auto mt-4 md:mt-auto px-8 py-3 rounded-full lg:text-xl text-secondary  border-2 border-secondary hover:border-black  hover:bg-black hover:text-white transition duration-300 ease-in-out">
                Try It Now
              </button>
            </div>
            <div className='col-span-12 md:col-span-6 md:px-10'>
              <img className='w-full ' src="assets/images/hand-btm.png" alt="union" />
            </div>
          </div>
        </div>
        <div className="col-span-12 md:order-1 -z-20 md:col-span-4 rounded-2xl bg-[#CA63CE] relative py-5 px-8 md:py-5 overflow-hidden flex flex-col">
          <img className='absolute -z-10 top-0 right-[25%] w-[50%]' src="assets/images/man-globe.png" alt="bitcoin" />
          <div className='bg-white mt-24 md:mt-28 rounded-2xl p-4'>
            <h2 className='text-2xl mb-4 font-bold'>Access a <span className='text-[#CA63CE]'>Global Marketplace</span> of Buyers & Creators</h2>
          </div>
          <p className='md:me-10 mt-5 text-white text-md'>
            Expand your reach like never before. Join a thriving global community where buyers and creators come together to collaborate, inspire, and trade. Whether you’re looking to sell your work or connect with other like-minded creatives, our platform opens doors to new opportunities and a worldwide audience.            </p>
          <Link className='flex items-center mt-5 gap-5 ms-auto underline text-white'>Explore More <ArrowRightAbove color='#CA63CE' /></Link>
        </div>

      </div>
    </div>
  )
}

export default WhyJoin



